import PropTypes from 'prop-types'
import React from 'react'
import { intlShape, injectIntl } from 'react-intl'
import { Button } from 'packages/components/buttons/button'
import {
  PrivacyContainer,
  PrivacyColumn,
  ItemContent,
  ItemDescription,
  Box,
  Paragraph,
  WrapperBox,
} from '../nodes'
import Image from 'packages/components/gatsby-image'
import Copytrack from './Copytrack'
import copytrackImg from './teaser-copytrack.jpg'
const CopyrightsSection = ({
  onChange: { toggleCopytrack, stopSync },
  values: { copytrackEnabled, copytrackSyncedCount },
  intl: { formatMessage },
  isMobile,
}) => {
  return (
    <WrapperBox isMobile={isMobile}>
      <PrivacyContainer>
        <PrivacyColumn>
          <Box isMobile={isMobile}>
            <Paragraph
              type='h2'
              mb={17}
              fw={700}
              color='#242426'
              data-intercom-target={'CookieBar'}
            >
              {formatMessage({ id: 'website.settings.copyrights.text1' })}
            </Paragraph>
            <ItemContent>
              <ItemDescription>
                {formatMessage({ id: 'website.settings.copyrights.text2' })}
              </ItemDescription>

              <ItemDescription>
                <Paragraph type='h2' mb={4} fw={700} color='#242426'>
                  {formatMessage({ id: 'website.settings.copyrights.text3.1.1' })}
                </Paragraph>
                <br />
                {formatMessage({ id: 'website.settings.copyrights.text3.1.2' })}
              </ItemDescription>

              <ItemDescription>
                <Paragraph type='h2' mb={4} fw={700} color='#242426'>
                  {formatMessage({ id: 'website.settings.copyrights.text3.2.1' })}{' '}
                </Paragraph>
                <br />
                {formatMessage({ id: 'website.settings.copyrights.text3.2.2' })}
              </ItemDescription>

              <ItemDescription>
                <Paragraph type='h2' mb={4} fw={700} color='#242426'>
                  {formatMessage({ id: 'website.settings.copyrights.text3.3.1' })}{' '}
                </Paragraph>
                <br />
                {formatMessage({ id: 'website.settings.copyrights.text3.3.2' })}
              </ItemDescription>

              {copytrackEnabled ? (
                <Copytrack copytrackSyncedCount={copytrackSyncedCount} stopSync={stopSync} />
              ) : (
                <Button
                  view='primary'
                  onClick={toggleCopytrack}
                  content={formatMessage({ id: 'website.settings.privacy.copytrack.disabled' })}
                />
              )}
              <br />
              <Image
                fluid={{
                  src: copytrackImg,
                  aspectRatio: 1.69, // 600 / 500
                }}
                alt=''
                fadeIn
                imgStyle={{ objectFit: 'contain' }}
                loading='lazy'
                backgroundColor='white'
              />
            </ItemContent>
          </Box>
        </PrivacyColumn>
      </PrivacyContainer>
    </WrapperBox>
  )
}

export default injectIntl(CopyrightsSection)

CopyrightsSection.propTypes = {
  intl: intlShape.isRequired,

  values: PropTypes.shape({
    cookies: PropTypes.bool.isRequired,
    fonts: PropTypes.bool.isRequired,
  }).isRequired,

  onChange: PropTypes.shape({
    toggleCookieBar: PropTypes.func.isRequired,
    toggleBlockFonts: PropTypes.func.isRequired,
  }).isRequired,

  isMobile: PropTypes.bool,
}
