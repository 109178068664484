import { compose } from 'redux'
import { connect } from 'react-redux'
import { open as openErrorModal } from 'packages/redux/modules/modalYesNo/actions'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'

import WebsiteSettings from '@configurator/components/settings'
export default compose(
  connect(
    // mapStateToProps
    () => ({}),
    // mapDispatchToProps
    (dispatch) => ({
      openErrorModal: ({ headerMessageId, yesMessageId, subMessageId }) =>
        dispatch(
          openErrorModal({
            headerMessageId,
            yesMessageId,
            subMessageId,
          })
        ),
      loaderStart: () => dispatch(loaderStart()),
      loaderStop: () => dispatch(loaderStop()),
    })
  )
)(WebsiteSettings)
