import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose } from 'redux'

import countryListGet from '@graphql/gql/common/countryListGet.gql'
import billingAccountDelete from '@graphql/gql/website/billingAccountDelete.gql'
import billingAccountGet from '@graphql/gql/website/billingAccountGet.gql'
import billingCreateCheckoutSession from '@graphql/gql/website/billingCreateCheckoutSession.gql'
import billingCreateSubscriptionByInvoice from '@graphql/gql/website/billingCreateSubscriptionByInvoice.gql'
import billingHistoryGet from '@graphql/gql/website/billingHistoryGet.gql'
import billingPlansGet from '@graphql/gql/website/billingPlansGet.gql'
import billingSubscriptionsGet from '@graphql/gql/website/billingSubscriptionsGet.gql'
import billingUpgradeTrialByInvoice from '@graphql/gql/website/billingUpgradeTrialByInvoice.gql'

import PaymentByPlanModal from '@configurator/components/billing/modals/paymentByPlan'
import { URLS } from 'packages/enum'
import { open as openErrorModal } from 'packages/redux/modules/modalYesNo/actions'
import { start as loaderStart, stop as loaderStop } from 'packages/redux/modules/pageLoader/actions'

const checkMarketplaceSettings = (marketplaceSettings) => {
  const marketplaceFields = ['name', 'address1', 'countryId']

  return marketplaceFields.every((item) => {
    return !isEmpty((marketplaceSettings[item] || '').toString())
  })
}

export const paymentWrapper = (Component) =>
  compose(
    connect(
      // mapStateToProps
      () => ({}),
      // mapDispatchToProps
      (dispatch) => ({
        openErrorModal: ({ headerMessageId, yesMessageId, subMessageId }) =>
          dispatch(
            openErrorModal({
              headerMessageId,
              yesMessageId,
              subMessageId,
            })
          ),
        loaderStart: () => dispatch(loaderStart()),
        loaderStop: () => dispatch(loaderStop()),
      })
    ),
    graphql(billingSubscriptionsGet, {
      props({ data: { loading, billingSubscriptionsGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(billingSubscriptionsGet)) {
          return { loading }
        }
        const { data } = billingSubscriptionsGet

        if (billingSubscriptionsGet && billingSubscriptionsGet.data) {
          return {
            paymentByInvoiceAllowed: !!data.paymentByInvoiceAllowed,
          }
        } else {
          return {
            paymentByInvoiceAllowed: false,
          }
        }
      },
    }),
    graphql(billingPlansGet, {
      options({ discountCode }) {
        return {
          variables: {
            discountCode,
          },
        }
      },
      props({ data: { loading, billingPlansGet, refetch } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(billingPlansGet)) {
          return { loading }
        }
        const { data } = billingPlansGet

        return {
          plans: data.plans,
          refetchPlans: refetch,
        }
      },
    }),
    graphql(billingAccountGet, {
      props({ data: { loading, billingAccountGet } }) {
        if (loading) {
          return { loading }
        }
        if (isEmpty(billingAccountGet)) {
          return { loading }
        }
        const { data } = billingAccountGet

        return {
          countryId: data.countryId,
          marketplaceSettingValid: checkMarketplaceSettings(data),
        }
      },
    }),
    graphql(billingCreateCheckoutSession, {
      props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
        return {
          async billingCreateCheckoutSession({ priceId, clientReferenceId }) {
            try {
              loaderStart()
              const res = await mutate({
                variables: {
                  priceId,
                  clientReferenceId,
                },
              })
              loaderStop()
              if (get(res, 'data.billingCreateCheckoutSession.errors._error')) {
                openErrorModal({
                  headerMessageId: 'error.header',
                  yesMessageId: 'OK',
                  subMessageId: get(res, 'data.billingCreateCheckoutSession.errors._error'),
                })
              }
              return res
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(billingCreateSubscriptionByInvoice, {
      props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
        return {
          async billingCreateSubscriptionByInvoice({ priceId }) {
            try {
              loaderStart()
              const res = await mutate({
                variables: {
                  priceId,
                },
                refetchQueries: [
                  {
                    query: billingSubscriptionsGet,
                  },
                  {
                    query: billingHistoryGet,
                  },
                ],
              })
              loaderStop()
              if (get(res, 'data.billingCreateSubscriptionByInvoice.errors._error')) {
                openErrorModal({
                  headerMessageId: 'error.header',
                  yesMessageId: 'OK',
                  subMessageId: get(res, 'data.billingCreateSubscriptionByInvoice.errors._error'),
                })
              }
              return res
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(billingUpgradeTrialByInvoice, {
      props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
        return {
          async billingUpgradeTrialByInvoice({ priceId }) {
            try {
              loaderStart()
              const res = await mutate({
                variables: {
                  priceId,
                },
                refetchQueries: [
                  {
                    query: billingSubscriptionsGet,
                  },
                  {
                    query: billingHistoryGet,
                  },
                ],
              })
              loaderStop()
              if (get(res, 'data.billingUpgradeTrialByInvoice.errors._error')) {
                openErrorModal({
                  headerMessageId: 'error.header',
                  yesMessageId: 'OK',
                  subMessageId: get(res, 'data.billingUpgradeTrialByInvoice.errors._error'),
                })
              }
              return res
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(billingAccountDelete, {
      props({ mutate, ownProps: { openErrorModal, loaderStart, loaderStop } }) {
        return {
          async billingAccountDelete() {
            try {
              loaderStart()
              const res = await mutate()
              loaderStop()
              if (get(res, 'data.billingAccountDelete.errors._error')) {
                openErrorModal({
                  headerMessageId: 'error.header',
                  yesMessageId: 'OK',
                  subMessageId: get(res, 'data.billingAccountDelete.errors._error'),
                })
              }
              return (window.location = URLS.root)
            } catch (err) {
              return {
                success: false,
              }
            }
          },
        }
      },
    }),
    graphql(countryListGet, {
      props({ data: { loading, countryListGet } }) {
        if (loading) {
          return {
            loading,
            options: [],
          }
        }
        if (isEmpty(countryListGet)) {
          return {
            loading,
            options: [],
          }
        }
        const { data } = countryListGet

        return {
          countriesList: data,
        }
      },
    })
  )(Component)

export default paymentWrapper(PaymentByPlanModal)
