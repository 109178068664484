import billingExchangeRateGet from '@graphql/gql/website/billingExchangeRateGet.gql'
import filter from 'lodash/filter'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { PLAN_PRICE_INTERVALS } from 'packages/constants'
import {
  PAYMENT_TYPE,
  // PAYMENT_STEPPER_TYPES,
  PLAN_TYPE,
} from 'packages/enum'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import { injectIntl, intlShape } from 'react-intl'
import { injectStripe } from 'react-stripe-elements'

// import AddressContainer from "@configurator/containers/billing/AddressContainer";
import { Success } from '@configurator/components/integrations/assets/Success'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'

import { getPlansByInterval } from '@configurator/components/billing/helpers'
import PriceDropdown from '../PriceDropdown/PriceDropdown'

import { featuresBox, planTypeDescription, pricesInUSD } from './constants'
import {
  Badge,
  Body,
  ButtonsContainer,
  Description,
  FeatureList,
  FeaturesTitle,
  Header,
  ModalContainer,
  PaymentContainer,
  PlanSelector,
  PlanSelectorHeader,
  PlanSelectors,
  PlanSelectorWrapper,
  PlanTypeDescription,
  PriceDropdownContainer,
  PriceDropdownMobileContainer,
  SavingsText,
  SelectorLabel,
  Step,
  StyledButton,
  StyledGrayButton,
  TextGray,
  ToggleContainer,
  ToggleOption,
  ToggleWrapper,
} from './styles/nodes'

const PaymentByPlan = ({
  plans,
  toType,
  open,
  onClose,
  billingCreateCheckoutSession,
  stripe,
  intl: { formatMessage },
}) => {
  const [loadExchangeRates, { data }] = useLazyQuery(billingExchangeRateGet)
  const [selectedPlanId, setSelectedPlanId] = useState()
  const [exchangeRates, setExchangeRates] = useState()
  const [selectedPlanType, setSelectedPlanType] = useState(toType)
  const [isMonthlyPlan, setIsMonthlyPlan] = useState(true)
  const [currency, setCurrency] = useState('EUR')

  // const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_TYPE.CREDIT_CARD)
  // const addressFormRef = useRef();

  // const currentCountryData = useMemo(() =>
  //     filter(countriesList, country => country.id === countryId)[0], [countriesList, countryId]);

  // const userLanguage = window?.navigator.language.toUpperCase();
  const isEUCountry = false // has(EU_COUNTRIES_CODE, currentCountryData ? currentCountryData?.shortName : userLanguage);

  // const readyToPaymentUSD = exchangeRates && exchangeRates[CURRENCY.USD] && !isEUCountry

  // const withConvertedPrice = useCallback(
  //   (plan) => {
  //     let price = currencyConversion(plan?.price, exchangeRates)
  //     return {
  //       ...plan,
  //       price,
  //     }
  //   },
  //   [exchangeRates]
  // )

  const plansByInterval = useMemo(() => {
    // if (readyToPaymentUSD) {
    //   const convertedPlans = map(plans, (plan) => {
    //     return withConvertedPrice(plan)
    //   })
    //   return getPlansByInterval(convertedPlans)
    // }
    return getPlansByInterval(plans)
  }, [plans])

  const plan = plansByInterval[selectedPlanType]

  const monthPlan = plan.month
  const yearPlan = plan.year

  const periodPlans = useMemo(
    () =>
      filter(
        orderBy(
          map(plansByInterval, (el) => {
            if (el.year?.planType === PLAN_TYPE.BASIC) {
              console.log(el)
              if (!isMonthlyPlan) {
                return {
                  ...el.year,
                  price: 2,
                }
              } else {
                return el.year
              }
            }
            if (!isMonthlyPlan) {
              return el.month
            } else {
              if (!el.hasOwnProperty('year')) {
                return null
              }
              return {
                ...el.year,
              }
            }
          }),
          'price'
        ),
        (plan) => plan !== null
      ),
    [isMonthlyPlan, plansByInterval]
  )

  // const savedForYear = useMemo(() => {
  //   if (!plan.month?.price) return
  //   return Math.floor(plan.month.price * 12 - plan.year?.price)
  // }, [plan])

  useEffect(() => {
    if (!isEUCountry) {
      if (!exchangeRates) {
        loadExchangeRates()
      }
    }
  }, [exchangeRates, loadExchangeRates, isEUCountry])

  // useEffect(() => {
  //   if (readyToPaymentUSD) {
  //     setCurrency(CURRENCY.USD)
  //   } else {
  //     setCurrency(CURRENCY.EUR)
  //   }
  // }, [readyToPaymentUSD])

  useEffect(() => {
    if (data) {
      const {
        billingExchangeRateGet: {
          data: { rates },
        },
      } = data
      setExchangeRates(rates)
    }
  }, [data])

  useEffect(() => {
    setSelectedPlanId(!isMonthlyPlan ? monthPlan?.id : yearPlan?.id)
  }, [isMonthlyPlan, monthPlan, yearPlan])

  useEffect(() => {
    const plansById = keyBy(plans, 'id')
    const selectedPlan = plansById[selectedPlanId]
    if (selectedPlan) {
      setSelectedPlanType(selectedPlan.planType)
    }
  }, [plans, selectedPlanId])

  useEffect(() => {
    setSelectedPlanType(toType)
  }, [toType])

  const handleRedirectToPayment = async () => {
    ANALYTICS.billingThirdStep()
    const res = await billingCreateCheckoutSession({
      priceId: selectedPlanId,
      clientReferenceId: null,
    })
    const sessionId = get(res, 'data.billingCreateCheckoutSession.data.sessionId')
    sessionId && stripe && stripe.redirectToCheckout({ sessionId })
  }

  // const handleRedirectToInvoice = async () => {
  //     ANALYTICS.billingThirdStep();
  //     if (type === PAYMENT_STEPPER_TYPES.BILLING_UPGRADE) {
  //         await billingUpgradeTrialByInvoice({
  //             priceId: selectedPlanId,
  //         });
  //     } else {
  //         await billingCreateSubscriptionByInvoice({
  //             priceId: selectedPlanId,
  //         });
  //     }
  //     onClose();
  // };

  const handlePlanSelect = (planId) => setSelectedPlanId(planId)

  const handlePay = async (/*type*/) => {
    // setSelectedPaymentType(type)

    await handleRedirectToPayment()
    // addressFormRef.current.handleSubmit();
  }

  const getPlanTypeTextName = (planType) => {
    if (planType === PLAN_TYPE.PRO) {
      return formatMessage({ id: 'subscription.plan.pro.header' })
    } else if (planType === PLAN_TYPE.PROMAX) {
      return formatMessage({ id: 'subscription.plan.promax.header' })
    } else if (planType === PLAN_TYPE.PROLITE) {
      return formatMessage({ id: 'subscription.plan.light.header' })
    } else if (planType === PLAN_TYPE.BASIC) {
      return formatMessage({ id: 'subscription.plan.basic.header' })
    }
    return planType
  }

  console.log(periodPlans)

  return (
    <ModalContainer
      open={open}
      onClose={() => onClose()}
      styles={{ width: '1000px', height: 'initial' }}
      mobileWide
    >
      <Header>
        {formatMessage({
          id: 'subscription.modal.step.2.title',
        })}
      </Header>

      <Description>
        {formatMessage({
          id: 'subscription.modal.step.2.description',
        })}
      </Description>

      <Body>
        <Step>
          <ToggleContainer>
            <ToggleWrapper>
              <ToggleOption active={isMonthlyPlan} onClick={() => setIsMonthlyPlan(false)}>
                {formatMessage({
                  id: 'billing.interval.monthly',
                })}
              </ToggleOption>
              <ToggleOption active={!isMonthlyPlan} onClick={() => setIsMonthlyPlan(true)}>
                {formatMessage({
                  id: 'billing.interval.yearly',
                })}
                <SavingsText>
                  {formatMessage(
                    {
                      id: 'subscription.modal.plan.price.savedMoneyInfo',
                    },
                    {
                      savedForYear: 20,
                    }
                  )}
                </SavingsText>
              </ToggleOption>
            </ToggleWrapper>

            <PriceDropdownContainer>
              <PriceDropdown currency={currency} setCurrency={setCurrency} />
            </PriceDropdownContainer>
          </ToggleContainer>

          <PriceDropdownMobileContainer>
            <PriceDropdown currency={currency} setCurrency={setCurrency} />
          </PriceDropdownMobileContainer>

          <PlanSelectors>
            {periodPlans
              .sort((a, b) => {
                console.log(a.price, b.price)
                return a.price - b.price
              })
              .map(({ id, interval, price: planPrice, planType }, index) => {
                const isSelected = id === selectedPlanId
                const isYear = interval === PLAN_PRICE_INTERVALS.year

                const price = isYear ? (planPrice / 12).toFixed(2) : Number(planPrice).toFixed(2)
                const priceUSD = (
                  pricesInUSD[isMonthlyPlan ? 'annually' : 'monthly'][planType] || ''
                ).toString()

                const [priceMain, cents] = price.split('.')
                const [priceMainUSD, centsUSD] = priceUSD.split('.')

                const currentCents = currency === 'USD' ? centsUSD : cents ?? '00'
                const currentPrice = currency === 'USD' ? priceMainUSD : priceMain

                console.log(planType, isMonthlyPlan)
                return (
                  <PlanSelector
                    key={index}
                    selected={isSelected}
                    isLight={false}
                    onClick={() => handlePlanSelect(id)}
                  >
                    <PlanSelectorWrapper>
                      <PlanSelectorHeader>
                        <SelectorLabel>
                          {getPlanTypeTextName(planType)}
                          {planType === PLAN_TYPE.PRO && (
                            <Badge>
                              {formatMessage({
                                id: 'subscription.modal.plan.popular',
                              })}
                            </Badge>
                          )}
                        </SelectorLabel>
                        <PlanTypeDescription>
                          {formatMessage({
                            id: planTypeDescription[planType],
                          })}
                        </PlanTypeDescription>
                      </PlanSelectorHeader>

                      <PaymentContainer>
                        {!isMonthlyPlan && planType === PLAN_TYPE.BASIC ? (
                          <b
                            style={{ color: 'gray' }}
                            dangerouslySetInnerHTML={{
                              __html: formatMessage(
                                {
                                  id: 'subscription.modal.plan.price.title',
                                },
                                {
                                  currency: currency,
                                  price: 2,
                                  cents: '00',
                                }
                              ),
                            }}
                          />
                        ) : (
                          <b
                            dangerouslySetInnerHTML={{
                              __html: formatMessage(
                                {
                                  id: 'subscription.modal.plan.price.title',
                                },
                                {
                                  currency: currency,
                                  price: currentPrice,
                                  cents: currentCents,
                                }
                              ),
                            }}
                          />
                        )}
                      </PaymentContainer>

                      <ButtonsContainer>
                        {!isMonthlyPlan && planType === PLAN_TYPE.BASIC ? (
                          <StyledGrayButton
                            active={planType === selectedPlanType}
                            content={`
                              ${formatMessage({
                                id: 'only available with yearly payment',
                              })}`}
                            fullWidth={true}
                          />
                        ) : (
                          <StyledButton
                            active={planType === selectedPlanType}
                            content={`
                            ${formatMessage({
                              id: 'subscription.modal.plan.button.payByCard.text',
                            })}
                            ${getPlanTypeTextName(planType)}`}
                            onClick={() => handlePay(PAYMENT_TYPE.CREDIT_CARD)}
                            fullWidth={true}
                          />
                        )}

                        {/* {paymentByInvoiceAllowed ? (
                        <ButtonContainer>
                          <p>
                            {formatMessage({
                              id: 'subscription.modal.plan.button.payByInvoice.desc',
                            })}
                          </p>
                          <StyledButton
                            view='secondaryBlack'
                            content={formatMessage({
                              id: 'subscription.modal.plan.button.payByInvoice.text',
                            })}
                            onClick={() => handlePay(PAYMENT_TYPE.INVOICE)}
                          />
                        </ButtonContainer>
                      ) : null} */}
                      </ButtonsContainer>
                    </PlanSelectorWrapper>

                    <FeaturesTitle>
                      {formatMessage({
                        id: featuresBox[planType].title,
                      })}
                    </FeaturesTitle>

                    <FeatureList>
                      {featuresBox[planType].lists.map((text, idx) => (
                        <li key={idx}>
                          <Success />

                          {formatMessage({
                            id: text,
                          })}
                        </li>
                      ))}
                    </FeatureList>
                  </PlanSelector>
                )
              })}
          </PlanSelectors>

          <TextGray>
            {formatMessage({
              id: 'billing.local.price',
            })}
          </TextGray>
        </Step>
      </Body>
    </ModalContainer>
  )
}

PaymentByPlan.propTypes = {
  intl: intlShape.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  marketplaceSettingValid: PropTypes.bool.isRequired,
  billingCreateCheckoutSession: PropTypes.func.isRequired,
  billingUpgradeTrialByInvoice: PropTypes.func.isRequired,
  billingCreateSubscriptionByInvoice: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  paymentByInvoiceAllowed: PropTypes.bool.isRequired,
  plans: PropTypes.array,

  toType: PropTypes.oneOf(Object.values(PLAN_TYPE)),
  countryId: PropTypes.number,
  countriesList: PropTypes.array,
}

PaymentByPlan.defaultProps = {
  plans: [],
  toType: PLAN_TYPE.PRO,
}

export default injectIntl(injectStripe(PaymentByPlan))
