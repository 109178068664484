import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { FieldArray } from 'formik'
import { FormDropdown } from 'packages/components/inputs'
import { FOOTER_SOCIAL_ICON_COLOR, FOOTER_ICON_TYPE, STYLE_FEATURE_PROPERTIES } from 'packages/enum'
import { Container, Heading, IconItem, IconList, LinkList, SelectGroup } from './nodes'
import { socialIcons } from './socialIcons'
import { getOptions } from './options'
import { LinkItem } from './linkItem'

function SocialLinksComponent({
  intl: { formatMessage },
  iconTypeName,
  iconColorName,
  socialLinksName,
  values,
}) {
  const socialLinks = values[socialLinksName] || []
  const iconType = values[STYLE_FEATURE_PROPERTIES.FOOTER_ICON_TYPE]
  const iconColor = values[STYLE_FEATURE_PROPERTIES.FOOTER_SOCIAL_ICON_COLOR]
  const { typeOptions, colorOptions } = getOptions({
    formatMessage,
    isIconTypeDisabled: iconColor === FOOTER_SOCIAL_ICON_COLOR.COLORED,
    isColoredDisabled: iconType === FOOTER_ICON_TYPE.ICON,
  })
  const selectedIds = socialLinks
    ? socialLinks.reduce((acc, value, index) => {
        acc[value.name] = index

        return acc
      }, {})
    : {}

  if (!iconType || !iconColor) {
    return null
  }
  return (
    <Container>
      <SelectGroup>
        <FormDropdown
          name={iconTypeName}
          options={typeOptions}
          label={formatMessage({ id: 'styles.socialLinks.iconType' })}
          fluid
        />
        <FormDropdown
          name={iconColorName}
          options={colorOptions}
          label={formatMessage({ id: 'styles.socialLinks.iconColor' })}
          fluid
        />
      </SelectGroup>
      <Heading>{formatMessage({ id: 'styles.socialLinks.heading.icons' })}</Heading>
      <FieldArray name={socialLinksName}>
        {({ unshift, remove, move }) => (
          <>
            <IconList>
              {Object.entries(socialIcons).map(([key, { name, icon }]) => {
                const selectedIndex = selectedIds[key]
                const isSelected = selectedIndex !== undefined

                const onClick = () => {
                  isSelected ? remove(selectedIndex) : unshift({ name: key, url: '' })
                }

                const Icon = icon[iconType][iconColor]
                const isShadow =
                  iconType === FOOTER_ICON_TYPE.ICON && iconColor === FOOTER_SOCIAL_ICON_COLOR.WHITE

                return (
                  <IconItem
                    key={key}
                    onClick={onClick}
                    $isSelected={isSelected}
                    $isShadow={isShadow}
                  >
                    <Icon title={name} />
                  </IconItem>
                )
              })}
            </IconList>
            {socialLinks.length > 0 && (
              <LinkList>
                {socialLinks.map(({ name }, index) => (
                  <LinkItem
                    key={name}
                    id={name}
                    index={index}
                    onDrop={(fromIndex, toIndex) => move(fromIndex, toIndex)}
                    onDelete={() => remove(index)}
                    fieldName={`${socialLinksName}.${index}.url`}
                    iconType={iconType}
                    iconColor={iconColor}
                  />
                ))}
              </LinkList>
            )}
          </>
        )}
      </FieldArray>
    </Container>
  )
}

SocialLinksComponent.propTypes = {
  iconTypeName: PropTypes.string.isRequired,
  iconColorName: PropTypes.string.isRequired,
  socialLinksName: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
}

export const SocialLinks = injectIntl(SocialLinksComponent)
