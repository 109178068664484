import { BLOCK_PROPERTY_KEYS, MESSAGE_TYPE, WEBSITE_BLOCK } from 'packages/enum'
import { formProptypes } from 'packages/utils/formikPropTypes'
import PropTypes from 'prop-types'
import { AlbumDetailsDisplayModeForm } from '../../pageUpdate/albumDetailsDisplayModeForm'
import { toast } from 'react-toastify'

export const DisplayAlbumMode = ({
  isShowNotice,
  onClose,
  lockModalProgress,
  updateBlockProperty,
  unLockModalProgress,
  values,
  formatMessage,
}) => {
  const saveAlbumDetailsPageBlockProperties = async (props) => {
    const block = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST)
    if (block) {
      // albumRowsDesktop: 'albumRowsDesktop',
      // albumModeDesktop: 'albumModeDesktop',
      // albumTitleDescriptionPositionDesktop: 'albumTitleDescriptionPositionDesktop',
      // albumRowsMobile: 'albumRowsMobile',
      // albumModeMobile: 'albumModeMobile',
      // albumTitleDescriptionPositionMobile: 'albumTitleDescriptionPositionMobile',
      // albumImageCaptions: 'albumImageCaptions',
      // albumSideMobile: 'albumSideMobile',
      // albumSideDesktop: 'albumSideDesktop',

      lockModalProgress()
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumRowsDesktop,
        propertyValue: props.albumRowsDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumModeDesktop,
        propertyValue: props.albumModeDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumTitleDescriptionPositionDesktop,
        propertyValue: props.albumTitleDescriptionPositionDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumRowsMobile,
        propertyValue: props.albumRowsMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumModeMobile,
        propertyValue: props.albumModeMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumTitleDescriptionPositionMobile,
        propertyValue: props.albumTitleDescriptionPositionMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumImageCaptionsMobile,
        propertyValue: props.albumImageCaptionsMobile,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumImageCaptionsDesktop,
        propertyValue: props.albumImageCaptionsDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumSideDesktop,
        propertyValue: props.albumSideDesktop,
      })
      await updateBlockProperty({
        blockId: block.id,
        propertyKey: BLOCK_PROPERTY_KEYS.albumSideMobile,
        propertyValue: props.albumSideMobile,
      })

      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      unLockModalProgress()
      toast.success(
        formatMessage({
          id: 'root.saved',
        })
      )
    }
  }

  const getAlbumDetailsPageBlockProperties = () => {
    const block = (values.blocks || []).find((el) => el.type === WEBSITE_BLOCK.ALBUM_LIST)
    if (block) {
      // albumRowsDesktop: 'albumRowsDesktop',
      // albumModeDesktop: 'albumModeDesktop',
      // albumTitleDescriptionPositionDesktop: 'albumTitleDescriptionPositionDesktop',
      // albumRowsMobile: 'albumRowsMobile',
      // albumModeMobile: 'albumModeMobile',
      // albumTitleDescriptionPositionMobile: 'albumTitleDescriptionPositionMobile',
      // albumImageCaptions: 'albumImageCaptions',

      const albumRowsDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumRowsDesktop
      )
      const albumModeDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumModeDesktop
      )
      const albumTitleDescriptionPositionDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumTitleDescriptionPositionDesktop
      )
      const albumRowsMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumRowsMobile
      )
      const albumModeMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumModeMobile
      )
      const albumTitleDescriptionPositionMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumTitleDescriptionPositionMobile
      )
      const albumSideMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumSideMobile
      )
      const albumSideDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumSideDesktop
      )
      const albumImageCaptionsMobile = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumImageCaptionsMobile
      )
      const albumImageCaptionsDesktop = (block.blockProperties || []).find(
        (el) => el.key === BLOCK_PROPERTY_KEYS.albumImageCaptionsDesktop
      )

      return {
        albumRowsDesktop: albumRowsDesktop?.value,
        albumModeDesktop: albumModeDesktop?.value,
        albumTitleDescriptionPositionDesktop: albumTitleDescriptionPositionDesktop?.value,
        albumRowsMobile: albumRowsMobile?.value,
        albumModeMobile: albumModeMobile?.value,
        albumTitleDescriptionPositionMobile: albumTitleDescriptionPositionMobile?.value,
        albumImageCaptionsMobile: albumImageCaptionsMobile?.value,
        albumImageCaptionsDesktop: albumImageCaptionsDesktop?.value,
        albumSideMobile: albumSideMobile?.value,
        albumSideDesktop: albumSideDesktop?.value,
      }
    }
  }

  return (
    <AlbumDetailsDisplayModeForm
      isShowNotice={isShowNotice}
      onClose={onClose}
      initialValues={getAlbumDetailsPageBlockProperties()}
      onSubmit={saveAlbumDetailsPageBlockProperties}
    />
  )
}

DisplayAlbumMode.propTypes = {
  isShowNotice: PropTypes.bool,
  onClose: PropTypes.func,
  lockModalProgress: PropTypes.func,
  updateBlockProperty: PropTypes.func,
  unLockModalProgress: PropTypes.func,
  ...formProptypes,
}
