import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import { intlShape, injectIntl } from 'react-intl'
import get from 'lodash/get'
import { useDispatch } from 'react-redux'
import { useApolloClient, useMutation } from 'react-apollo'
import { useInterval } from 'packages/helpers/useInterval'
import { DOMAIN_TYPES } from 'packages/constants'
import { open as openErrorModal } from 'packages/redux/modules/modalYesNo/actions'
import { PREMIUM_STATUSES } from 'packages/enum'
import domainGet from '@graphql/gql/website/domainGet.gql'
import domainDeleteMutation from '@graphql/gql/website/domainDelete.gql'
import domainSetMutation from '@graphql/gql/website/domainSet.gql'
import domainRetryMutation from '@graphql/gql/website/domainRetry.gql'
import DomainForm from '../domain/DomainForm'

const PremiumConnect = ({ websiteId, isMobile }) => {
  const client = useApolloClient()

  const dispatch = useDispatch()

  const [startPremiumDomainRequest, setStartPremiumDomainRequest] = useState(false)

  const [premiumDomainInfo, setPremiumDomainInfo] = useState({})

  // const [entriSellLoading, setEntriSellLoading] = useState(false)

  // const [entriConnectLoading, setEntriConnectLoading] = useState(false)

  // const [entriTokenGet] = useMutation(entriTokenGetMutation)
  const [domainDelete] = useMutation(domainDeleteMutation)
  const [domainSet] = useMutation(domainSetMutation)
  const [domainRetry] = useMutation(domainRetryMutation)

  const deletePremiumDomain = () =>
    domainDelete({
      variables: {
        requestId: premiumDomainInfo.id,
      },
    }).then(
      ({
        data: {
          domainDelete: { success },
        },
      }) => {
        if (success) {
          setPremiumDomainInfo({ isRequestExist: false, domain: '' })
          setStartPremiumDomainRequest(false)
        }
      }
    )

  // toggle change domain
  const changePremiumDomain = useCallback(
    async ({ domain }) => {
      const data = await domainSet({
        variables: {
          websiteId,
          domain,
        },
      })
      if (get(data, 'data.domainSet.errors._error')) {
        dispatch(
          openErrorModal({
            headerMessageId: 'error.header',
            yesMessageId: 'OK',
            subMessageId: get(data, 'data.domainSet.errors._error'),
          })
        )
      } else {
        setStartPremiumDomainRequest(true)
      }
    },
    [dispatch, domainSet, websiteId]
  )

  useEffect(() => {
    const handleOnEntriClose = (event) => {
      console.log('onEntriClose', event.detail)
      if (event.detail && event.detail.success) {
        if (event.detail.setupType === 'purchase') {
          // domain: "ladoalexi.com"
          // freeDomain: false
          // lastStatus: "FINISHED_SUCCESSFULLY"
          // provider: "ionos"
          // setupType: "purchase"
          // success: true

          if (
            event.detail.lastStatus === 'FINISHED_SUCCESSFULLY' ||
            event.detail.lastStatus === 'FINISHED_SUCCESSFULLY_MANUAL'
          ) {
            // check if custom domain is already connected
            changePremiumDomain({ domain: event.detail.domain })
          }
        } else {
          // domain: "ladoalexi.com"
          // lastStatus: "FINISHED_SUCCESSFULLY_MANUAL"
          // provider: ""
          // setupType: null
          // success: true

          changePremiumDomain({ domain: event.detail.domain })
        }
      }
    }
    console.log('registered onEntriClose')
    window.addEventListener('onEntriClose', handleOnEntriClose, false)

    return () => window.removeEventListener('onEntriClose', handleOnEntriClose, false)
  }, [changePremiumDomain])

  // toggle change domain
  const retryDomainCheck = async () => {
    await domainRetry()
    setStartPremiumDomainRequest(true)
  }

  const updateRequestStatus = useCallback(() => {
    client
      .query({
        query: domainGet,
        variables: { websiteId },
        fetchPolicy: 'network-only',
      })
      .then(
        ({
          data: {
            domainGet: { data, success },
          },
        }) => {
          if (success) {
            setPremiumDomainInfo(data)
          }
        }
      )
  }, [client, websiteId])

  useEffect(() => {
    if (websiteId && client) {
      updateRequestStatus(true)
    }
  }, [startPremiumDomainRequest, websiteId, client, updateRequestStatus])

  useInterval(() => {
    if (
      startPremiumDomainRequest &&
      premiumDomainInfo.isRequestExist &&
      premiumDomainInfo.status !== PREMIUM_STATUSES.ACTIVE
    ) {
      updateRequestStatus()
    }
  }, 10000)

  return (
    <DomainForm
      type={DOMAIN_TYPES.PREMIUM_DOMAIN}
      domain={premiumDomainInfo.domainName}
      changeDomain={changePremiumDomain}
      deleteDomain={deletePremiumDomain}
      domainRetry={retryDomainCheck}
      premiumStatus={premiumDomainInfo.status}
      failureCode={premiumDomainInfo.failureCode}
      attemptCount={premiumDomainInfo.attemptCount}
      lastAttemptDate={premiumDomainInfo.lastAttemptDate}
      isRequestExist={premiumDomainInfo.isRequestExist}
      isMobile={isMobile}
    />
  )
}

PremiumConnect.propTypes = {
  websiteId: PropTypes.number.isRequired,
  freeDomain: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  isMobile: PropTypes.bool,
}

PremiumConnect.defaultProps = {}

export default injectIntl(PremiumConnect)
