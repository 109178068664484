import React from 'react'
import { intlShape, injectIntl } from 'react-intl'
import { Button } from 'packages/components/buttons/button'
import websiteCopytrackIntegrationLoginLinkCreateMutation from '@graphql/gql/website/websiteCopytrackIntegrationLoginLinkCreate.gql'
import websiteCopytrackIntegrationStatisticsQuery from '@graphql/gql/website/websiteCopytrackIntegrationStatistics.gql'
import { useQuery, useMutation } from 'react-apollo'
import { Flex } from '../nodes'

const Copytrack = ({ copytrackSyncedCount, stopSync, intl: { formatMessage } }) => {
  const [websiteCopytrackIntegrationLoginLinkCreate] = useMutation(
    websiteCopytrackIntegrationLoginLinkCreateMutation
  )

  const { data: { websiteCopytrackIntegrationStatistics } = {} } = useQuery(
    websiteCopytrackIntegrationStatisticsQuery,
    {
      fetchPolicy: 'network-only',
    }
  )
  const getLink = async () => {
    const res = await websiteCopytrackIntegrationLoginLinkCreate({
      variables: {},
    })
    if (res && res?.data?.websiteCopytrackIntegrationLoginLinkCreate?.data?.token) {
      window.open(
        'https://app.copytrack.com?login_token=' +
          res?.data?.websiteCopytrackIntegrationLoginLinkCreate?.data?.token,
        '_blank'
      )
    }
  }

  const copytrackData = websiteCopytrackIntegrationStatistics?.data || {}
  return (
    <div>
      <span>Images synced: {copytrackSyncedCount}</span>
      <br />
      <span>Inbox count: {copytrackData.inboxData?.count} </span>
      <br />
      <span>Hits count: {copytrackData.hitsData?.count} </span>
      <br />
      <br />
      <Flex>
        <Button
          view='primary'
          onClick={getLink}
          content={formatMessage({
            id: 'login.copytrack',
          })}
        />
        &nbsp; &nbsp;
        <Button
          type='button'
          content={formatMessage({ id: 'login.copytrack.stop' })}
          view='secondaryBlack'
          onClick={stopSync}
        />
      </Flex>
    </div>
  )
}

export default injectIntl(Copytrack)

Copytrack.propTypes = {
  intl: intlShape.isRequired,
}
