import { HeaderStyled } from 'packages/components/layout/nodes'
import { injectIntl } from 'react-intl'
import Image from 'packages/components/gatsby-image'
import {
  Root,
  Header,
  Body,
  Cards,
  Card,
  CardTitle,
  CardDescription,
} from '@configurator/components/services/nodes'
import fiverrImage from './assets/fiverr.png'
import elfsightImage from './assets/elfsight.png'
import weglotImage from './assets/weglot.png'
import ecwidImage from './assets/ecwid.png'
import jotformImage from './assets/jotform.png'
import pikdImage from './assets/pikd.jpg'

const cards = [
  {
    id: 'pikd',
    title: 'services.pikd.title',
    description: 'services.pikd.description',
    url: 'https://pikd.io/?utm_source=vsble&utm_medium=banner&utm_campaign=vsble',
    image: pikdImage,
  },
  {
    id: 'fiverr',
    title: 'services.fiverr.title',
    description: 'services.fiverr.description',
    url: 'https://www.fiverr.com/cp/vsble-experts-marketplace?utm_source=893962&utm_medium=cx_affiliate&utm_campaign=&afp=&cxd_token=893962_30489953&show_join=true',
    image: fiverrImage,
  },
  {
    id: 'elfsight',
    title: 'services.elfsight.title',
    description: 'services.elfsight.description',
    url: 'https://go.elfsight.io/click?pid=1210&offer_id=3',
    image: elfsightImage,
  },
  {
    id: 'weglot',
    title: 'services.weglot.title',
    description: 'services.weglot.description',
    url: 'https://weglot.com/?fp_ref=vsble',
    image: weglotImage,
  },
  {
    id: 'ecwid',
    title: 'services.ecwid.title',
    description: 'services.ecwid.description',
    url: 'http://open.ecwid.com/3rq3FR',
    image: ecwidImage,
  },
  {
    id: 'jotform',
    title: 'services.jotform.title',
    description: 'services.jotform.description',
    url: 'https://link.jotform.com/vsble-1BlHNXBapn',
    image: jotformImage,
  },
]

function Services({ intl: { formatMessage } }) {
  return (
    <>
      <HeaderStyled backArrow />
      <Root>
        <Header>{formatMessage({ id: 'services.title' })}</Header>
        <Body>
          <Cards>
            {cards.map(({ id, title, description, url, image }) => (
              <Card key={id}>
                <CardTitle>{formatMessage({ id: title })}</CardTitle>
                <CardDescription>{formatMessage({ id: description })}</CardDescription>
                <a href={url} target='_blank' rel='noopener noreferrer'>
                  <Image
                    fluid={{
                      src: image,
                      aspectRatio: 1.2, // 600 / 500
                    }}
                    alt=''
                    fadeIn
                    imgStyle={{ objectFit: 'contain' }}
                    loading='lazy'
                    backgroundColor='white'
                  />
                </a>
              </Card>
            ))}
          </Cards>
        </Body>
      </Root>
    </>
  )
}

export default injectIntl(Services)
