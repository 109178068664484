import Loader from '@configurator/components/loader/Loader'
import WebsiteAlbum from '@configurator/containers/AlbumContainer'
import AlbumUpdateModal from '@configurator/containers/AlbumUpdateModalContainer'
import NavigationContainer from '@configurator/containers/NavigationContainer'
import PageUpdateModal from '@configurator/containers/PageUpdateModalContainer'
import { useSubscription } from '@configurator/providers/subscription'
import { SettingsButton } from 'packages/components/buttons'
import { ExpandIcon, ShrinkIcon } from 'packages/components/icons'
import ModalConfirmDelete from 'packages/components/modalConfirmDelete'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import { TabPropTypes } from 'packages/components/tabs'
import { MESSAGE_TYPE, URLS, WEBSITE_BLOCK, WEBSITE_PAGE_TYPES } from 'packages/enum'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { intlShape } from 'react-intl'
import { AlbumTitle, AlbumTitleContainer, ChooseViewContainer, Container, Preview } from './nodes'
const Page = ({
  blocks,
  loading,
  refetchPagePreview,
  tab: { setBackArrowState, clearBackArrowState },
  match: {
    params: { pageId },
  },
  history,
  deleteAlbum,
  expandedView,
  changeAlbumView,
  intl: { formatMessage },
}) => {
  const { permissions } = useSubscription()

  const [loadingDeleteAlbum, setLoadingDeleteAlbum] = useState(false)
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [updateAlbumModalOpen, setUpdateAlbumModalOpen] = useState(false)
  const [updateDialogVisible, setUpdateDialogVisible] = useState(false)
  // слева отображаем только блок ALBUM_DETAIL, во всех других случаях отображается дефолтная навигация
  const displayBlocks = useMemo(() => {
    return ((blocks || {}).blocks || []).filter(
      (item) =>
        item.type === WEBSITE_BLOCK.ALBUM_DETAIL || item.type === WEBSITE_BLOCK.EXPANDED_ALBUM_LIST
    )
  }, [blocks])

  useEffect(() => {
    return () => changeAlbumView({ expandedView: false })
  }, [changeAlbumView])

  const handleDeleteAlbum = (albumId) => {
    setDeleteDialogVisible(false)
    setLoadingDeleteAlbum(true)
    deleteAlbum({ albumId }).then(() => {
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_PAGE,
        }),
        '*'
      )
      window.frames['preview-frame']?.postMessage(
        JSON.stringify({
          name: MESSAGE_TYPE.UPDATE_META,
        }),
        '*'
      )
      setLoadingDeleteAlbum(false)
      setUpdateAlbumModalOpen(false)
      history.push(URLS.website_config)
      clearBackArrowState()
    })
  }

  const isSimplePage = displayBlocks.find((item) => item.type === WEBSITE_BLOCK.EXPANDED_ALBUM_LIST)

  useEffect(() => {
    if (displayBlocks.length > 0) {
      const isPhotoPage = displayBlocks.find(
        (item) => item.type === WEBSITE_BLOCK.EXPANDED_ALBUM_LIST
      )
      setBackArrowState(
        'tabs.tab.portfolio',
        () => {
          history.push(URLS.website_config)
          clearBackArrowState()
        },
        isPhotoPage ? (
          <SettingsButton
            color='dark'
            onClick={(e) => {
              e.stopPropagation()
              setUpdateDialogVisible(true)
            }}
          />
        ) : (
          <SettingsButton
            color='dark'
            onClick={(e) => {
              e.stopPropagation()
              setUpdateAlbumModalOpen(true)
            }}
          />
        )
      )
    }
  }, [displayBlocks, clearBackArrowState, history, setBackArrowState])

  if (loading) {
    return <Loader />
  }

  const renderBlock = (b) => {
    let block = {}
    if (b.type === WEBSITE_BLOCK.EXPANDED_ALBUM_LIST) {
      block = {
        ...b.albums[0],
        albumId: b.albums[0].id,
      }
    } else {
      block = b
    }

    return (
      <div>
        <AlbumTitleContainer>
          <AlbumTitle>{block.title}</AlbumTitle>
          <PermissionsOverlay isAllowed={permissions.ALBUM_EXTENDED_VIEW} proMax={true}>
            <ChooseViewContainer onClick={changeAlbumView}>
              {formatMessage({
                id: expandedView ? 'album.label.view.shrink' : 'album.label.view.expand',
              })}
              {expandedView ? <ShrinkIcon /> : <ExpandIcon />}
            </ChooseViewContainer>
          </PermissionsOverlay>
        </AlbumTitleContainer>
        <WebsiteAlbum
          type={b.type}
          albumId={block.albumId}
          pageId={block.pageId}
          pageUrl={pageId}
          photos={block.photos || []}
          refetchPagePreview={refetchPagePreview}
          isSimplePage={isSimplePage}
        />
        {deleteDialogVisible && (
          <ModalConfirmDelete
            open={deleteDialogVisible}
            headerMessageId='album.delete.header'
            subMessageId='album.delete.subHeader'
            onClickYes={() => handleDeleteAlbum(block.albumId)}
            onClickCancel={() => setDeleteDialogVisible(false)}
            onClose={() => setDeleteDialogVisible(false)}
          />
        )}
        {updateAlbumModalOpen && (
          <AlbumUpdateModal
            open
            pageId={block.pageId}
            pageUrl={pageId}
            albumId={block.albumId}
            pageType={WEBSITE_PAGE_TYPES.ALBUM_LIST}
            initialValues={{
              title: block.title,
              description: block.description,
            }}
            onClose={() => setUpdateAlbumModalOpen(false)}
            onDelete={() => setDeleteDialogVisible(true)}
            loadingDeleteAlbum={loadingDeleteAlbum}
          />
        )}
        {updateDialogVisible && (
          <PageUpdateModal
            pageId={block.pageId}
            open
            onClose={() => setUpdateDialogVisible(false)}
            onDelete={() => setDeleteDialogVisible(true)}
            slideshowEnabled={false}
          />
        )}
      </div>
    )
  }
  return displayBlocks.length > 0 ? (
    <Container>
      {displayBlocks.map((item) => (
        <Preview key={`${item.type}${item.pageId}`}>{renderBlock(item)}</Preview>
      ))}
    </Container>
  ) : (
    <NavigationContainer
      disablePageFetch
      tab={{ setBackArrowState: () => {}, clearBackArrowState: () => {} }}
    />
  )
}

Page.propTypes = {
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  refetchPagePreview: PropTypes.func,
  history: PropTypes.object,
  deleteAlbum: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  expandedView: PropTypes.bool,
  changeAlbumView: PropTypes.func,
  ...TabPropTypes,
}

Page.defaultProps = {
  loading: false,
  history: {},
  refetchPagePreview: () => null,
  expandedView: false,
  changeAlbumView: () => null,
}

export default Page
