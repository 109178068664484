import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { intlShape } from 'react-intl'
import { useQuery } from 'react-apollo'

import { Button } from 'packages/components/buttons'
import billingAccountGetQuery from '@graphql/gql/website/billingAccountGet.gql'
import { useSubscription } from '../configurator/useSubscription'

import { Container, CloseIcon, WrapperButtonVerify, WrapperButtons, Content, Title } from './nodes'
import { ABO_STATUS } from 'packages/enum'

const ConfirmEmailBanner = ({ handleOpen, intl: { formatMessage } }) => {
  const [open, setOpen] = useState(false)

  const { data: { billingAccountGet } = {} } = useQuery(billingAccountGetQuery)

  const { aboStatus } = useSubscription()

  useEffect(() => {
    const emailVerified = billingAccountGet?.data?.emailVerified

    if (billingAccountGet && billingAccountGet?.data) {
      if (aboStatus === ABO_STATUS.TRIALING && !emailVerified) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }
  }, [aboStatus, billingAccountGet, billingAccountGet?.data?.emailVerified])

  if (!open) {
    return null
  }

  return (
    <Container style={{ background: '#3347ff', zIndex: 60 }}>
      <Content>
        <Title
          dangerouslySetInnerHTML={{
            __html: formatMessage({ id: 'preview.banner.confirmEmail.title' }),
          }}
        />

        <WrapperButtons>
          <WrapperButtonVerify>
            <Button
              type='button'
              content={formatMessage({
                id: 'preview.banner.confirmEmail.button',
              })}
              onClick={handleOpen}
            />
          </WrapperButtonVerify>

          <CloseIcon onClick={() => setOpen(false)}>
            <span
              style={{
                color: 'white',
              }}
            >
              {formatMessage({
                id: 'unpaid.banner.close',
              })}
            </span>
            <svg
              style={{
                fill: 'white',
              }}
              width='14'
              height='14'
              viewBox='0 0 14 14'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'></path>
            </svg>
          </CloseIcon>
        </WrapperButtons>
      </Content>
    </Container>
  )
}

ConfirmEmailBanner.contextTypes = {
  intl: intlShape.isRequired,
  handleOpen: PropTypes.func.isRequired,
}

export default injectIntl(ConfirmEmailBanner)
