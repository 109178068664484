import { Formik, Form as FormikForm } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { FormDropdown } from 'packages/components/inputs'
import { formProptypes } from 'packages/utils/formikPropTypes'

import { Button } from 'packages/components/buttons'
import styled from 'styled-components'
import { Footer, StyledDropdown } from './nodes'
const validationSchema = Yup.object().shape({
  text: Yup.string(),
})

export const NoticeText = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #343840;
`

export const AlbumDetailsDisplayModeForm = (
  { onSubmit, initialValues, onClose, isShowNotice },
  { intl: { formatMessage } }
) => {
  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        onSubmit(values)
      }}
      render={({ values }) => {
        return (
          <FormikForm>
            {isShowNotice && (
              <NoticeText>The setting will affect all albums on this page</NoticeText>
            )}
            <div style={{ marginTop: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
              Desktop
            </div>
            {values.albumModeDesktop === 'grid' && (
              <StyledDropdown>
                <FormDropdown
                  name='albumRowsDesktop'
                  label='Grid Rows Type'
                  options={[
                    {
                      key: '2',
                      value: '2',
                      text: '2 image per row',
                    },
                    {
                      key: '3',
                      value: '3',
                      text: '3 image per row',
                    },
                    {
                      key: '4',
                      value: '4',
                      text: '4 image per row',
                    },
                    {
                      key: '5',
                      value: '5',
                      text: '5 image per row',
                    },
                    {
                      key: '6',
                      value: '6',
                      text: '6 image per row',
                    },
                  ]}
                />
              </StyledDropdown>
            )}

            <StyledDropdown>
              <FormDropdown
                name='albumModeDesktop'
                label='Mode'
                options={[
                  {
                    key: 'style',
                    value: 'style',
                    text: 'Style',
                  },
                  {
                    key: 'grid',
                    value: 'grid',
                    text: 'Grid',
                  },
                  {
                    key: 'vertical',
                    value: 'vertical',
                    text: 'Vertical',
                  },
                  {
                    key: 'horizontal',
                    value: 'horizontal',
                    text: 'Horizontal',
                  },
                ]}
              />
            </StyledDropdown>
            {values.albumModeDesktop === 'grid' ? (
              <StyledDropdown>
                <FormDropdown
                  name='albumTitleDescriptionPositionDesktop'
                  label='Alignment Title & Description'
                  options={[
                    {
                      key: 'left',
                      value: 'left',
                      text: 'Left',
                    },
                    {
                      key: 'center',
                      value: 'center',
                      text: 'Center',
                    },
                    {
                      key: 'right',
                      value: 'right',
                      text: 'Right',
                    },
                  ]}
                />
              </StyledDropdown>
            ) : null}
            {values.albumModeDesktop === 'grid' || values.albumModeDesktop === 'style' ? null : (
              <StyledDropdown>
                <FormDropdown
                  name='albumSideDesktop'
                  label='Position Title & Description'
                  options={[
                    {
                      key: 'side',
                      value: 'side',
                      text: 'Side',
                    },
                    {
                      key: 'top',
                      value: 'top',
                      text: 'Top',
                    },
                  ]}
                />
              </StyledDropdown>
            )}

            <StyledDropdown>
              <FormDropdown
                name='albumImageCaptionsDesktop'
                label='Image Captions'
                options={[
                  {
                    key: 'yes',
                    value: 'yes',
                    text: 'Yes',
                  },
                  {
                    key: 'no',
                    value: 'no',
                    text: 'No',
                  },
                ]}
              />
            </StyledDropdown>

            <div style={{ marginTop: '40px', marginBottom: '10px', fontWeight: 'bold' }}>
              Mobile
            </div>
            {values.albumModeMobile === 'grid' && (
              <StyledDropdown>
                <FormDropdown
                  name='albumRowsMobile'
                  label='Grid Rows Type'
                  options={[
                    {
                      key: '1',
                      value: '1',
                      text: '1 image per row',
                    },
                    {
                      key: '2',
                      value: '2',
                      text: '2 image per row',
                    },
                    {
                      key: '3',
                      value: '3',
                      text: '3 image per row',
                    },
                    {
                      key: '4',
                      value: '4',
                      text: '4 image per row',
                    },
                  ]}
                />
              </StyledDropdown>
            )}

            <StyledDropdown>
              <FormDropdown
                name='albumModeMobile'
                label='Mode'
                options={[
                  {
                    key: 'style',
                    value: 'style',
                    text: 'Style',
                  },
                  {
                    key: 'grid',
                    value: 'grid',
                    text: 'Grid',
                  },
                  {
                    key: 'vertical',
                    value: 'vertical',
                    text: 'Vertical',
                  },
                  {
                    key: 'horizontal',
                    value: 'horizontal',
                    text: 'Horizontal',
                  },
                ]}
              />
            </StyledDropdown>
            {values.albumModeMobile === 'grid' ? (
              <StyledDropdown>
                <FormDropdown
                  name='albumTitleDescriptionPositionMobile'
                  label='Alignment Title & Description'
                  options={[
                    {
                      key: 'left',
                      value: 'left',
                      text: 'Left',
                    },
                    {
                      key: 'center',
                      value: 'center',
                      text: 'Center',
                    },
                    {
                      key: 'right',
                      value: 'right',
                      text: 'Right',
                    },
                  ]}
                />
              </StyledDropdown>
            ) : null}
            {/* <StyledDropdown>
              <FormDropdown
                name='albumSideMobile'
                label='Position Title & Description'
                options={[
                  {
                    key: 'side',
                    value: 'side',
                    text: 'Side',
                  },
                  {
                    key: 'top',
                    value: 'top',
                    text: 'Top',
                  },
                ]}
              />
            </StyledDropdown> */}
            <StyledDropdown>
              <FormDropdown
                name='albumImageCaptionsMobile'
                label='Image Captions'
                options={[
                  {
                    key: 'yes',
                    value: 'yes',
                    text: 'Yes',
                  },
                  {
                    key: 'no',
                    value: 'no',
                    text: 'No',
                  },
                ]}
              />
            </StyledDropdown>
            <Footer>
              <Button
                type='button'
                content={formatMessage({
                  id: 'newDesign.modal.buttons.cancel',
                })}
                fluid
                view='primaryGray'
                onClick={onClose}
              />
              <Button
                content={formatMessage({
                  id: 'newDesign.modal.buttons.save',
                })}
                onClick={onSubmit}
                fluid
              />
            </Footer>
          </FormikForm>
        )
      }}
    />
  )
}

AlbumDetailsDisplayModeForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAlbumItemUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool,
  ...formProptypes,
}

AlbumDetailsDisplayModeForm.defaultProps = {
  open: false,
}

AlbumDetailsDisplayModeForm.contextTypes = {
  intl: PropTypes.object.isRequired,
}
