import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import { intlShape, injectIntl } from 'react-intl'
import { useApolloClient } from 'react-apollo'
import { useSubscription } from '@configurator/providers/subscription'
import domainGet from '@graphql/gql/website/domainGet.gql'
import { Button } from 'packages/components/buttons/button'
import PermissionsOverlay from 'packages/components/permissionsOverlay/permissionsOverlay'
import PremiumConnect from './PremiumConnect'
import {
  Paragraph,
  PremiumHeaderContainer,
  CustomDomainPreview,
  CustomDomainPreviewContainer,
  CustomDomainPreviewWrapper,
  ChangeIconWrapper,
  WrapperDescription,
  Box,
  WrapperButton,
  WrapperButtuns,
  FlexColumn,
  WrapperBox,
  // PremiumText,
} from '../nodes'
import { LockIcon } from 'packages/components/icons'
import { ChangeIcon } from 'packages/components/icons/arrows/change'
import { useWindowSize } from 'packages/helpers/useWindowSize'

// const isDebug = __ENTRI_APPLICATION_ID__.includes('staging')
// const records = __ENTRI_APPLICATION_ID__.includes('staging')
//   ? [
//       {
//         type: 'A',
//         host: '@',
//         value: '46.4.163.183',
//         ttl: 300,
//       },
//       {
//         type: 'A',
//         host: 'www',
//         value: '46.4.163.183',
//         ttl: 300,
//       },
//     ]
//   : [
//       {
//         type: 'A',
//         host: '@',
//         value: '94.130.249.72',
//         ttl: 300,
//       },
//       {
//         type: 'A',
//         host: 'www',
//         value: '94.130.249.72',
//         ttl: 300,
//       },
//     ]

const PremiumSection = ({
  websiteId,
  freeDomain,
  premiumDomain,
  isMobile,
  intl: { formatMessage },
}) => {
  const client = useApolloClient()
  const size = useWindowSize()

  // const dispatch = useDispatch()
  const { permissions } = useSubscription()

  const [premiumDomainInfo, setPremiumDomainInfo] = useState({})

  const updateRequestStatus = useCallback(() => {
    client
      .query({
        query: domainGet,
        variables: { websiteId },
        fetchPolicy: 'network-only',
      })
      .then(
        ({
          data: {
            domainGet: { data, success },
          },
        }) => {
          if (success) {
            setPremiumDomainInfo(data)
          }
        }
      )
  }, [client, websiteId])

  useEffect(() => {
    if (websiteId && client) {
      updateRequestStatus(true)
    }
  }, [websiteId, client, updateRequestStatus])

  return (
    <WrapperBox isMobile={isMobile}>
      <PremiumHeaderContainer>
        <Paragraph
          type='h1'
          fz={'24px'}
          fw={700}
          mb={24}
          color='#242426'
          data-intercom-target={'ConnectCustomDomain'}
        >
          {formatMessage({
            id: 'website.settings.premium.title',
          })}
        </Paragraph>

        <WrapperDescription>
          <div>
            <Paragraph
              type='h2'
              fz={16}
              fw={300}
              mw={456}
              lh={'28px'}
              color='#242426'
              textAlign='left'
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: 'website.settings.premium.text1',
                }),
              }}
            />
            <Paragraph
              type='h2'
              fz={16}
              mt={16}
              fw={300}
              mw={456}
              lh={'28px'}
              color='#242426'
              textAlign='left'
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: 'website.settings.premium.text1.1',
                }),
              }}
            />
          </div>
          <CustomDomainPreviewWrapper width={size.clientWidth}>
            <CustomDomainPreviewContainer>
              <CustomDomainPreview>
                <div>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div>
                  <div>
                    <span>LA</span>
                  </div>
                  <div>
                    <div>
                      <LockIcon width={7} />
                    </div>
                    <div>
                      https://<span>{freeDomain}</span>
                      .vsble.me
                    </div>

                    <ChangeIconWrapper>
                      <ChangeIcon height={60} />
                    </ChangeIconWrapper>
                  </div>
                </div>
              </CustomDomainPreview>
              <div></div>
            </CustomDomainPreviewContainer>

            <CustomDomainPreviewContainer>
              <CustomDomainPreview>
                <div>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div>
                  <div>
                    <span>LA</span>
                  </div>
                  <div>
                    <div>
                      <LockIcon width={7} />
                    </div>
                    <div>https://yourdomain.com</div>
                  </div>
                </div>
              </CustomDomainPreview>
            </CustomDomainPreviewContainer>
            <div></div>
          </CustomDomainPreviewWrapper>
        </WrapperDescription>
      </PremiumHeaderContainer>

      {premiumDomain || premiumDomainInfo?.domainName ? (
        <Box isPremiumDomain isMobile={isMobile}>
          <Paragraph
            type='h2'
            mb={17}
            fw={700}
            color='#242426'
            isPremiumDomain
            data-intercom-target={'WebsiteBuyCustomDomain'}
          >
            {formatMessage({ id: 'website.settings.premium.your.domain' })}
          </Paragraph>

          <PermissionsOverlay isAllowed={permissions.CUSTOM_DOMAIN}>
            <WrapperButtuns>
              <FlexColumn style={{ minWidth: '56%' }}>
                <PremiumConnect websiteId={websiteId} isMobile={isMobile} />
              </FlexColumn>
            </WrapperButtuns>
          </PermissionsOverlay>
        </Box>
      ) : (
        <>
          <Box isPremiumDomain isMobile={isMobile}>
            <Paragraph type='h3' fz={12} fw={300} color='#242426' mb={0}>
              {formatMessage({ id: 'website.settings.premium.option.1' })}
            </Paragraph>
            <br />

            <Paragraph
              type='h2'
              mb={17}
              fw={700}
              color='#242426'
              isPremiumDomain
              data-intercom-target={'WebsiteBuyCustomDomain'}
            >
              {formatMessage({ id: 'website.settings.premium.option.1.buy.custom.domain' })}
            </Paragraph>

            <Paragraph type='h2' fz={17} fw={300} color='#242426' mb={24}>
              {formatMessage({
                id: 'website.settings.premium.option.1.buy.custom.domain.purchase',
              })}
            </Paragraph>

            <PermissionsOverlay isAllowed={permissions.CUSTOM_DOMAIN}>
              <WrapperButtuns>
                <WrapperButton isPremiumDomain>
                  <FlexColumn style={{ minWidth: '300px' }}>
                    <Paragraph type='h2' fw={700} color='#242426'>
                      1. {formatMessage({ id: 'website.settings.premium.option.1.buy' })}
                    </Paragraph>
                    <Paragraph style={{ maxWidth: '300px' }} type='h3' fw={500} color='#242426'>
                      {formatMessage({ id: 'website.settings.premium.option.1.buy.desc' })}
                      <br />
                      <Paragraph
                        as={'a'}
                        type='h3'
                        fw={500}
                        color='#242426'
                        href='https://support.vsble.me/en/articles/10628294-how-to-transfer-and-host-your-domain-with-vsble'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {formatMessage({ id: 'website.settings.premium.option.1.buy.watch.video' })}
                      </Paragraph>
                    </Paragraph>

                    <Button
                      as='a'
                      href='https://domains.vsble.me'
                      type='submit'
                      target='_blank'
                      rel='noopener noreferrer'
                      view='primary'
                      content={formatMessage({
                        id: 'website.settings.premium.option.1.find.and.buy',
                      })}
                    />
                  </FlexColumn>
                </WrapperButton>
                <FlexColumn style={{ minWidth: '56%' }}>
                  <Paragraph type='h2' fw={700} color='#242426'>
                    {formatMessage({ id: 'website.settings.premium.buy.connect.2' })}
                  </Paragraph>
                  <Paragraph style={{ maxWidth: '340px' }} type='h3' fw={500} color='#242426'>
                    {formatMessage({ id: 'website.settings.premium.option.1.connect' })}
                  </Paragraph>
                  <PremiumConnect websiteId={websiteId} isMobile={isMobile} />
                </FlexColumn>
              </WrapperButtuns>
            </PermissionsOverlay>
          </Box>

          <Box isPremiumDomain isMobile={isMobile}>
            <Paragraph type='h3' fz={12} lh={'28px'} fw={300} color='#242426' mb={0}>
              {formatMessage({ id: 'website.settings.premium.option.2' })}
            </Paragraph>
            <br />

            <Paragraph
              type='h2'
              mb={17}
              fw={700}
              color='#242426'
              isPremiumDomain
              data-intercom-target={'WebsiteBuyCustomDomain'}
            >
              {formatMessage({ id: 'website.settings.premium.option.2.buy.custom.domain' })}
            </Paragraph>

            <Paragraph type='h2' fz={17} lh={'28px'} fw={300} color='#242426' mb={24}>
              {formatMessage({
                id: 'website.settings.premium.option.2.buy.custom.domain.purchase',
              })}
            </Paragraph>

            <PermissionsOverlay isAllowed={permissions.CUSTOM_DOMAIN}>
              <WrapperButtuns>
                <WrapperButton isPremiumDomain>
                  <FlexColumn style={{ minWidth: '300px' }}>
                    <Paragraph type='h2' fw={700} color='#242426'>
                      1. {formatMessage({ id: 'website.settings.premium.option.2.buy' })}
                    </Paragraph>
                    <Paragraph style={{ maxWidth: '300px' }} type='h3' fw={500} color='#242426'>
                      {formatMessage({ id: 'website.settings.premium.option.2.buy.desc' })}
                      <br />
                      <Paragraph
                        as={'a'}
                        type='h3'
                        fw={500}
                        color='#242426'
                        href='https://support.vsble.me/en/articles/10628294-how-to-transfer-and-host-your-domain-with-vsble'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {formatMessage({ id: 'website.settings.premium.option.2.buy.watch.video' })}
                      </Paragraph>
                    </Paragraph>

                    <Button
                      as='a'
                      href='https://domains.vsble.me'
                      type='submit'
                      target='_blank'
                      rel='noopener noreferrer'
                      view='primary'
                      content={formatMessage({
                        id: 'website.settings.premium.option.2.find.and.buy',
                      })}
                    />
                  </FlexColumn>
                </WrapperButton>
                <FlexColumn style={{ minWidth: '56%' }}>
                  <Paragraph type='h2' fw={700} color='#242426'>
                    {formatMessage({ id: 'website.settings.premium.buy.connect.2' })}
                  </Paragraph>
                  <Paragraph style={{ maxWidth: '340px' }} type='h3' fw={500} color='#242426'>
                    {formatMessage({ id: 'website.settings.premium.option.2.connect' })}
                  </Paragraph>
                  <PremiumConnect websiteId={websiteId} isMobile={isMobile} />
                </FlexColumn>
              </WrapperButtuns>
            </PermissionsOverlay>
          </Box>

          <Box isPremiumDomain isMobile={isMobile}>
            <Paragraph type='h3' fz={12} lh={'28px'} fw={300} color='#242426' mb={0}>
              {formatMessage({ id: 'website.settings.premium.option.3' })}
            </Paragraph>
            <br />

            <Paragraph
              type='h2'
              mb={17}
              fw={700}
              color='#242426'
              isPremiumDomain
              data-intercom-target={'WebsiteBuyCustomDomain'}
            >
              {formatMessage({ id: 'website.settings.premium.option.3.buy.custom.domain' })}
            </Paragraph>

            <Paragraph
              type='h2'
              fz={17}
              lh={'28px'}
              fw={300}
              color='#242426'
              mb={24}
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: 'website.settings.premium.option.3.buy.custom.domain.purchase',
                }),
              }}
            ></Paragraph>

            <PermissionsOverlay isAllowed={permissions.CUSTOM_DOMAIN}>
              <WrapperButtuns>
                <WrapperButton isPremiumDomain>
                  <FlexColumn style={{ minWidth: '300px' }}>
                    <Paragraph type='h2' fw={700} color='#242426'>
                      1. {formatMessage({ id: 'website.settings.premium.option.3.buy' })}
                    </Paragraph>
                    <Paragraph style={{ maxWidth: '300px' }} type='h3' fw={500} color='#242426'>
                      {formatMessage({ id: 'website.settings.premium.option.3.buy.desc' })}&nbsp;
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://support.vsble.me/en/collections/11691812-connecting-domain-from-popular-providers'
                      >
                        here
                      </a>
                    </Paragraph>
                    <iframe
                      style={{ width: '100%', border: 'none', height: '200px' }}
                      src='https://www.youtube.com/embed/8Ob74IYZNT8'
                      frameborder='0'
                      allowfullscreen='allowfullscreen'
                      title='YouTube video player'
                    ></iframe>
                  </FlexColumn>
                </WrapperButton>
                <FlexColumn style={{ minWidth: '56%' }}>
                  <Paragraph type='h2' fw={700} color='#242426'>
                    {formatMessage({ id: 'website.settings.premium.buy.connect.2' })}
                  </Paragraph>
                  <Paragraph style={{ maxWidth: '340px' }} type='h3' fw={500} color='#242426'>
                    {formatMessage({ id: 'website.settings.premium.option.3.connect' })}
                  </Paragraph>
                  <PremiumConnect websiteId={websiteId} isMobile={isMobile} />
                </FlexColumn>
              </WrapperButtuns>
            </PermissionsOverlay>
          </Box>
        </>
      )}
    </WrapperBox>
  )
}

PremiumSection.propTypes = {
  websiteId: PropTypes.number.isRequired,
  freeDomain: PropTypes.string.isRequired,
  premiumDomain: PropTypes.string,
  intl: intlShape.isRequired,
  isMobile: PropTypes.bool,
}

PremiumSection.defaultProps = {}

export default injectIntl(PremiumSection)
