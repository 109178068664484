import debounce from 'lodash/debounce'
import { Semantic } from 'packages/components'
import { Button } from 'packages/components/buttons'
import { VideoIcon } from 'packages/components/icons'
import { FormInput } from 'packages/components/inputs'
import Message from 'packages/components/message/Message'
import { FEATURE_PROPERTY_KEYS, HOSTING_URLS, MESSAGE_TYPE, VIDEO_HOSTING } from 'packages/enum'
import { formProptypes } from 'packages/utils/formikPropTypes'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Description, VideoInput } from './nodes'

const getHosting = (videoId, hosting) => {
  if (hosting === VIDEO_HOSTING.YOUTUBE) {
    return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1&controls=0`
  } else if (hosting === VIDEO_HOSTING.VIMEO) {
    return `https://player.vimeo.com/video/${videoId}?muted=1&loop=1&autoplay=1&controls=0`
  }
}

const updateVideoDebounce = debounce((func = () => null) => {
  func()
}, 1000)

const VideoComponent = (
  {
    // handleSubmit,
    errors,
    status,
    isSubmitting,
    values,
    refetchPreviewMetaData,
    setStatus,
    setSubmitting,
    setValues,
    featureUpdate,
    initialValues,
    refetchData,
    setValuesSlideshow,
  },
  { intl: { formatMessage } }
) => {
  const defaultError = 'upload.modal.error.header'
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const error = Object.keys(errors).length || (status && !!status.error)

  const isNewVideoUrl = useMemo(
    () => values.videoId !== initialValues.videoId,
    [values, initialValues]
  )

  const changeValues = useCallback(() => {
    const { url } = queryString.parseUrl(values.videoUrl || '')
    if (url.indexOf('vimeo.com') !== -1) {
      const videoId = url.split('vimeo.com/').pop()
      if (videoId) {
        setValues({
          ...values,
          hosting: VIDEO_HOSTING.VIMEO,
          videoId,
          videoUrl: HOSTING_URLS.VIMEO + videoId,
        })
        setStatus({})
      } else {
        setStatus({ error: formatMessage({ id: 'upload.modal.error.invalid' }) })
      }
    } else if (values.videoUrl.indexOf('youtube.com') !== -1) {
      const videoId = values.videoUrl.split('watch?v=').pop()
      setValues({
        ...values,
        hosting: VIDEO_HOSTING.YOUTUBE,
        videoId: videoId,
        videoUrl: values.videoUrl,
      })
      setStatus({})
    } else if (values.videoUrl.indexOf('youtu.be') !== -1) {
      const videoId = values.videoUrl.split('.be/').pop().split('?si=')[0]
      setValues({
        ...values,
        hosting: VIDEO_HOSTING.YOUTUBE,
        videoId: videoId,
        videoUrl: values.videoUrl,
      })
      setStatus({})
    } else if (!values.hosting) {
      setStatus({ error: formatMessage({ id: 'upload.modal.error.invalid' }) })
    }
    setIsButtonDisabled(false)
  }, [formatMessage, setStatus, setValues, values])

  useEffect(() => {
    if (values.videoUrl === '') {
      setValues({ ...values, videoId: undefined, hosting: undefined, videoUrl: undefined })

      updateVideoDebounce.cancel()

      return
    }

    if (values.videoUrl) {
      setIsButtonDisabled(true)
      updateVideoDebounce(changeValues)
    } else if (values.videoId || values.hosting) {
      setValues({
        ...values,
        videoId: undefined,
        hosting: undefined,
      })
    }
  }, [changeValues, setValues, values, values.videoUrl])

  const updateVideo = () => {
    setSubmitting(true)

    const getHostingName = (hosting) => {
      if (hosting === VIDEO_HOSTING.YOUTUBE) {
        return 'youtube'
      } else if (hosting === VIDEO_HOSTING.VIMEO) {
        return `vimeo`
      }
    }

    if (!isNewVideoUrl) {
      setValuesSlideshow({ ...values, videoId: undefined, videoUrl: undefined })
    }

    featureUpdate({
      featureId: values.id,
      properties: {
        [FEATURE_PROPERTY_KEYS.sliderVideoUrl]: isNewVideoUrl ? values.videoId : null,
        [FEATURE_PROPERTY_KEYS.sliderVideoProvider]: isNewVideoUrl
          ? getHostingName(values.hosting)
          : null,
      },
    })
      .then((res) => {
        let {
          data: { featureUpdate: { errors, success } = {} },
        } = res
        if (success) {
          refetchData()
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_PAGE,
            }),
            '*'
          )
          window.frames['preview-frame']?.postMessage(
            JSON.stringify({
              name: MESSAGE_TYPE.UPDATE_META,
            }),
            '*'
          )

          return refetchPreviewMetaData()
        }
        return setStatus({ error: errors._error || defaultError })
      })
      .catch((err) => {
        return setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      })
  }

  return (
    <Semantic.Form noValidate error={error} loading={isSubmitting} onSubmit={() => {}}>
      <Message error={error} errors={errors} content={status && status.error} />
      <Description>{formatMessage({ id: 'upload.video.text' })}</Description>
      <VideoInput>
        <FormInput name='videoUrl' label={formatMessage({ id: 'upload.video.label.paste' })} />
      </VideoInput>
      {values.videoId && values.hosting && (
        <iframe
          title={values.videoId}
          src={getHosting(values.videoId, values.hosting)}
          frameBorder='0'
          width='100%'
          height='200'
        />
      )}
      <Button
        type='button'
        onClick={updateVideo}
        fluid
        view={isNewVideoUrl ? 'secondaryBlack' : 'secondaryGray'}
        content={formatMessage({
          id: isNewVideoUrl ? 'upload.video.label.add' : 'upload.video.label.remove',
        })}
        icon={isNewVideoUrl ? <VideoIcon /> : null}
        disabled={isButtonDisabled || !values.videoId}
      />
    </Semantic.Form>
  )
}

VideoComponent.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  featureUpdate: PropTypes.func,
  refetchPreviewMetaData: PropTypes.func,
  setValuesSlideshow: PropTypes.func,
  refetchData: PropTypes.func,
  ...formProptypes,
}

VideoComponent.defaultProps = {
  initialValues: {},
  loading: false,
  refetchPreviewMetaData: () => null,
  featureUpdate: () => null,
  refetchData: () => null,
}

VideoComponent.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default VideoComponent
