export const Success = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='10'
      viewBox='0 0 11 10'
      fill='none'
      {...props}
    >
      <path
        d='M10.1667 1.66663L4.38598 8.33329L1 5.11317'
        stroke='#58C367'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
