import { Semantic } from 'packages/components'
import { media } from 'packages/components/media'
import styled from 'styled-components'

export const PriceDropdownContainer = styled(Semantic.Dropdown)`
  display: flex !important;
  align-items: center;

  &.active {
    > svg {
      transform: rotate(180deg);
    }
  }

  .menu {
    margin-top: 8px !important;
  }

  .menu.transition.visible {
    width: 50px;
    border: none;
    box-shadow: 0 24px 60px rgb(0 0 0 / 15%);
    padding: 4px 0;
    z-index: 101;
    border-radius: 8px;

    &:after {
      content: none;
    }

    .header {
      margin: 10px 0 20px 0;
      padding: 0 20px;
    }

    > div {
      .item {
        height: 40px;
        display: flex;
        align-items: center;
        margin: 0 4px !important;
        padding: 0 8px !important;
        border-radius: 2px;
      }

      > a {
        cursor: pointer;
        display: block;
        border: none;
        height: auto;
        text-align: left;
        line-height: 1em;
        color: rgba(0, 0, 0, 0.87);
        font-size: 13px;
        text-transform: none;
        box-shadow: none;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
          color: rgba(0, 0, 0, 0.95);
        }
      }
    }

    .item {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 4px 1px !important;
      padding: 5px 12px 0 !important;
      border-radius: 4px;
      font-weight: 500;

      &.active {
        background-color: #f6f6f6;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .divider {
      margin: 12px 20px;
    }
  }
`

export const Initials = styled.div`
  white-space: nowrap;
  color: #666a73;
  padding-top: 4px;

  ${media.phone`
        text-overflow: ellipsis;
        max-width: 85px;
        white-space: nowrap;
    `}

  ${media.tablet`
         max-width: 250px;
         white-space: wrap;
         white-space: nowrap;
   `};
`

export const Currency = styled.span`
  margin: 0 6px 0 8px;
  font-weight: 600;
`
