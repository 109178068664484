import { Semantic } from 'packages/components'

import { ChevronDown } from 'packages/components/header/icons'
import { injectIntl } from 'react-intl'
import { Currency, Initials, PriceDropdownContainer } from './styles/nodes'

const currencies = ['USD', 'EUR']

const PriceDropdown = ({ currency, setCurrency, intl: { formatMessage } }) => {
  return (
    <PriceDropdownContainer
      data-testid='main-page-profile-dropdown'
      pointing='top right'
      icon={<ChevronDown color='#666A73' />}
      trigger={
        <Initials>
          {formatMessage({
            id: 'subscription.modal.title.price',
          })}
          <Currency>{currency}</Currency>
        </Initials>
      }
    >
      <Semantic.Dropdown.Menu>
        {currencies.map((price, idx) => (
          <Semantic.Dropdown.Item
            key={idx}
            text={price}
            active={price === currency}
            onClick={() => setCurrency(price)}
          />
        ))}
      </Semantic.Dropdown.Menu>
    </PriceDropdownContainer>
  )
}
export default injectIntl(PriceDropdown)
