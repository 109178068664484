import { URLS } from 'packages/enum'

// Landing
import LandingRoot from '@landing/containers/HomeContainer'
import CreateAccountContainer from '@landing/containers/CreateAccountContainer'
import LoginFormContainer from '@landing/containers/LoginFormContainer'
import SendEmailFormContainer from '@landing/containers/SendEmailFormContainer'
import ResetPasswordFormContainer from '@landing/containers/ResetPasswordFormContainer'
import Layout from '@landing/components/Layout'
import StripeRedirect from '@landing/components/stripeRedirect/StripeRedirect'

// Admin
import AdminRoot from '@admin/components/home/Home'
import UsersListContainer from '@admin/containers/UsersListContainer'

// Configurator
import ConfiguratorRoot from '@configurator/containers/RootContainer'

import WebsiteSEO from '@configurator/containers/SEOContainer'
import { ConfigPanel } from '@configurator/components/configPanel/ConfigPanel'
import Statistics from '@configurator/components/statistics'
import Home from '@configurator/containers/HomeContainer'
import { Configurator } from './components/Configurator'
import Templates from './containers/TemplatesContainer'
import PageBlocksContainer from './containers/PageBlocksContainer'
import WebsiteSettings from './containers/WebsiteSettingsContainer'
import NavigationContainer from './containers/NavigationContainer'
import BillingContainer from './containers/billing/BillingContainer'
import IntegrationsList from './containers/integrations/IntegrationsList'
import IntegrationEcwid from '@configurator/components/integrations/details/ecwid/Ecwid'
import IntegrationTawk from '@configurator/components/integrations/details/tawk/Tawk'
import IntegrationLivechat from '@configurator/components/integrations/details/livechat/Livechat'
import IntegrationCalendly from '@configurator/components/integrations/details/calendly/Calendly'
import IntegrationHelpspace from '@configurator/components/integrations/details/helpspace/Helpspace'
import IntegrationChatra from '@configurator/components/integrations/details/chatra/Chatra'
import IntegrationTidio from '@configurator/components/integrations/details/tidio/Tidio'
import IntegrationOptinly from '@configurator/components/integrations/details/optinly/Optinly'
import IntegrationBlogger from '@configurator/components/integrations/details/blogger/Blogger'
import IntegrationWeglot from '@configurator/components/integrations/details/weglot/Weglot'
import IntegrationTypeform from '@configurator/components/integrations/details/typeform/Typeform'
import IntegrationJotform from '@configurator/components/integrations/details/jotform/Jotform'
import DropInBlog from '@configurator/components/integrations/details/dropnblog/DropInBlog'
import CustomIntegrationsDetails from './containers/integrations/CustomIntegrationsDetails'
import Services from 'src/@configurator/components/services'

export default [
  {
    path: URLS.website_root,
    component: ConfiguratorRoot,
    routes: [
      {
        path: URLS.website_root,
        component: Home,
        routes: [
          {
            path: `${URLS.website_config}`,
            component: Configurator,
            routes: [
              {
                path: `${URLS.website_config}/`,
                component: ConfigPanel,
                routes: [
                  // Navigation / Albums block
                  {
                    exact: true,
                    path: [`${URLS.website_config}/`, `${URLS.website_config}/page/:pageId`],
                    component: NavigationContainer,
                  },
                  {
                    exact: true,
                    path: `${URLS.website_config}/blocks/:pageId`,
                    component: PageBlocksContainer,
                  },
                ],
              },
            ],
          },
          {
            exact: true,
            path: `${URLS.website_edit_template}`,
            component: Templates,
          },
          {
            exact: true,
            path: `${URLS.website_settings}`,
            component: WebsiteSettings,
          },
          {
            exact: true,
            path: `${URLS.website_seo}`,
            component: WebsiteSEO,
          },
          {
            exact: true,
            path: `${URLS.website_services}`,
            component: Services,
          },
          {
            path: `${URLS.website_billing}`,
            component: BillingContainer,
            routes: [],
          },
          {
            exact: true,
            path: `${URLS.website_statistics}`,
            component: Statistics,
          },
          {
            exact: true,
            path: `${URLS.website_integrations}`,
            component: IntegrationsList,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_ecwid}`,
            component: IntegrationEcwid,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_tawk}`,
            component: IntegrationTawk,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_livechat}`,
            component: IntegrationLivechat,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_calendly}`,
            component: IntegrationCalendly,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_helpspace}`,
            component: IntegrationHelpspace,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_chatra}`,
            component: IntegrationChatra,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_tidio}`,
            component: IntegrationTidio,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_optinly}`,
            component: IntegrationOptinly,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_blogger}`,
            component: IntegrationBlogger,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_weglot}`,
            component: IntegrationWeglot,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_typeform}`,
            component: IntegrationTypeform,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_jotform}`,
            component: IntegrationJotform,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_dropinblog}`,
            component: DropInBlog,
          },
          {
            exact: true,
            path: `${URLS.website_integrations_details_custom}`,
            component: CustomIntegrationsDetails,
          },
        ],
      },
    ],
  },
  {
    path: URLS.website_account,
    component: Layout,
    routes: [
      {
        path: URLS.website_create_account,
        component: CreateAccountContainer,
      },
      {
        path: URLS.website_create_free_account,
        component: CreateAccountContainer,
      },
      {
        path: URLS.website_login,
        component: LoginFormContainer,
      },
      {
        path: URLS.website_reset_password,
        component: SendEmailFormContainer,
      },
      {
        path: URLS.website_new_password,
        component: ResetPasswordFormContainer,
      },
      {
        path: URLS.website_stripe_redirect,
        component: StripeRedirect,
      },
    ],
  },
  {
    path: URLS.website_account,
    component: LandingRoot,
    routes: [
      {
        path: URLS.website_create_account,
        component: CreateAccountContainer,
      },
      {
        path: URLS.website_create_free_account,
        component: CreateAccountContainer,
      },
      {
        path: URLS.website_login,
        component: LoginFormContainer,
      },
      {
        path: URLS.website_reset_password,
        component: SendEmailFormContainer,
      },
      {
        path: URLS.website_new_password,
        component: ResetPasswordFormContainer,
      },
    ],
  },
  {
    path: URLS.admin,
    component: AdminRoot,
    routes: [
      {
        exact: true,
        path: URLS.admin,
        component: UsersListContainer,
      },
      {
        path: URLS.admin_users,
        component: UsersListContainer,
      },
    ],
  },
]
