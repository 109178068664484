import { fromJS } from 'immutable'

import { getDefaultLocale } from 'packages/intl'
import { SERVICE } from 'packages/enum'

const messagesCommon = require('packages/intl/messages/common/messages.en')
const messagesConfigurator = require('packages/intl/messages/configurator/messages.en')
const messagesLanding = require('packages/intl/messages/landing/messages.en')
// export const initialState = {
//     user,
//     intl: {
//         defaultLocale,
//         ...getMessages({locale: cookieLocale, type: SERVICE.configurator}),
//     },
//     userAgent: {
//         isMobile: !!md.mobile(),
//         isTablet: !!md.tablet(),
//         isBot: md.is('bot') || 'isBot' in req.query,
//     },
//     preview: {
//         meta: {},
//         structure: {},
//     },
// };

export default fromJS({
  intl: {
    locale: getDefaultLocale(),
    type: SERVICE.configurator,
    // type,
    messages: {
      ...messagesCommon.default,
      ...messagesConfigurator.default,
      ...messagesLanding.default,
    },
  },
  userAgent: {},
  preview: {
    meta: {},
    structure: {},
  },
})
