import isEmpty from 'lodash/isEmpty'
import {
  FEATURE_PROPERTY_KEYS,
  HOSTING_URLS,
  OBJECT_FIT_KEYS,
  SLIDER_TYPE_KEYS,
} from 'packages/enum'
import { formProptypes } from 'packages/utils/formikPropTypes'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef } from 'react'

import PhotosComponent from '../../containers/SlideshowPhotoContainer'
import VideoComponent from '../../containers/SlideshowVideoContainer'

import { Container, Tab, Tabs } from './nodes'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const Slideshow = (
  {
    loading,
    values,
    items,
    setFieldValue,
    refetchPreviewMetaData,
    refetchItems,
    setValues,
    setStatus,
    featureData,
    featureUpdate,
    refetchData,
  },
  { intl: { formatMessage } }
) => {
  const prevValues = usePrevious(values)

  useEffect(() => {
    if (!isEmpty(featureData)) {
      const { properties = [] } = featureData || {}
      const album = properties.find((el) => el.key === FEATURE_PROPERTY_KEYS.album) || {}
      const transitionSpeed =
        properties.find((el) => el.key === FEATURE_PROPERTY_KEYS.transitionSpeed) || {}
      const objectFit = properties.find((el) => el.key === FEATURE_PROPERTY_KEYS.objectFit) || {}
      const sliderType = properties.find((el) => el.key === FEATURE_PROPERTY_KEYS.sliderType) || {}
      const sliderVideoUrl =
        properties.find((el) => el.key === FEATURE_PROPERTY_KEYS.sliderVideoUrl) || {}
      const sliderVideoProvider =
        properties.find((el) => el.key === FEATURE_PROPERTY_KEYS.sliderVideoProvider) || {}
      setValues({
        ...featureData,
        albumId: album.value,
        transitionSpeed: transitionSpeed.value,
        objectFit: objectFit.value,
        fitContain: objectFit.value === OBJECT_FIT_KEYS.contains,
        fitCover: objectFit.value === OBJECT_FIT_KEYS.cover,
        sliderType: sliderType.value,
        typePhoto: sliderType.value === SLIDER_TYPE_KEYS.photo,
        typeVideo: sliderType.value === SLIDER_TYPE_KEYS.video,
        sliderVideoUrl: sliderVideoUrl.value,
        sliderVideoProvider: sliderVideoProvider.value,
      })
    }
  }, [featureData, setValues])

  const updateFeature = useCallback(() => {
    const defaultError = 'slideshow.modal.error.default'
    featureUpdate({
      featureId: values.id,
      properties: {
        [FEATURE_PROPERTY_KEYS.sliderType]: values.sliderType,
      },
    })
      .then((res) => {
        let {
          data: { featureUpdate: { errors, success } = {} },
        } = res
        if (success) {
          refetchData()
          return refetchPreviewMetaData()
        }
        return setStatus({ error: errors._error || defaultError })
      })
      .catch((err) => {
        return setStatus({ error: __PRODUCTION__ ? defaultError : err.toString() })
      })
  }, [featureUpdate, refetchData, refetchPreviewMetaData, setStatus, values.id, values.sliderType])

  useEffect(() => {
    if (prevValues && prevValues.sliderType !== values.sliderType) {
      updateFeature()
    }
  }, [prevValues, updateFeature, values.sliderType])

  useEffect(() => {
    if (prevValues) {
      if (prevValues.albumId !== values.albumId) {
        refetchItems({ albumId: +values.albumId })
      }
    }
  }, [prevValues, refetchItems, values])

  const onTabPhotoClick = useCallback(() => {
    if (values.typeVideo && values.sliderType === SLIDER_TYPE_KEYS.video) {
      setFieldValue('typeVideo', false)
      setFieldValue('typePhoto', true)
      setFieldValue('sliderType', SLIDER_TYPE_KEYS.photo)
    }
  }, [setFieldValue, values.sliderType, values.typeVideo])

  const onTabVideoClick = useCallback(() => {
    if (values.typePhoto && values.sliderType === SLIDER_TYPE_KEYS.photo) {
      setFieldValue('typePhoto', false)
      setFieldValue('typeVideo', true)
      setFieldValue('sliderType', SLIDER_TYPE_KEYS.video)
    }
  }, [setFieldValue, values.sliderType, values.typePhoto])

  const getVideoUrl = () => {
    if (values.sliderVideoUrl) {
      if (values.sliderVideoProvider === 'vimeo') {
        return HOSTING_URLS.VIMEO + values.sliderVideoUrl
      } else {
        return HOSTING_URLS.YOUTUBE + values.sliderVideoUrl
      }
    } else {
      return ''
    }
  }
  return (
    <Container>
      <Tabs>
        <Tab active={values.sliderType === SLIDER_TYPE_KEYS.photo} onClick={onTabPhotoClick}>
          {formatMessage({ id: 'slideshow.modal.type.photo' })}
        </Tab>
        <Tab active={values.sliderType === SLIDER_TYPE_KEYS.video} onClick={onTabVideoClick}>
          {formatMessage({ id: 'slideshow.modal.type.video' })}
        </Tab>
      </Tabs>
      {!loading && values.typePhoto && (
        <PhotosComponent
          items={items}
          refetchPreviewMetaData={refetchPreviewMetaData}
          refetchItems={refetchItems}
          refetchData={refetchData}
          featureUpdate={featureUpdate}
          initialValues={{
            id: values.id,
            albumId: values.albumId,
            transitionSpeed: values.transitionSpeed,
            objectFit: values.objectFit,
            fitContain: values.fitContain,
            fitCover: values.fitCover,
          }}
        />
      )}
      {!loading && values.typeVideo && (
        <VideoComponent
          initialValues={{
            id: values.id,
            videoId: values.sliderVideoUrl,
            hosting: values.sliderVideoProvider ? values.sliderVideoProvider.toUpperCase() : '',
            videoUrl: getVideoUrl(),
          }}
          setValuesSlideshow={setValues}
          refetchData={refetchData}
          featureUpdate={featureUpdate}
          refetchPreviewMetaData={refetchPreviewMetaData}
        />
      )}
    </Container>
  )
}

Slideshow.propTypes = {
  featureData: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  items: PropTypes.array,
  refetchPreviewMetaData: PropTypes.func,
  refetchItems: PropTypes.func,
  featureUpdate: PropTypes.func,
  refetchData: PropTypes.func,
  ...formProptypes,
}

Slideshow.defaultProps = {
  loading: false,
  open: false,
  items: [],
  featureData: {},
  refetchPreviewMetaData: () => null,
  refetchItems: () => null,
  featureUpdate: () => null,
  refetchData: () => null,
}

Slideshow.contextTypes = {
  intl: PropTypes.object.isRequired,
}

export default Slideshow
