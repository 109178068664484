import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { injectStripe } from 'react-stripe-elements'

import { useSubscription } from '@configurator/components/configurator/useSubscription'
import Loader from '@configurator/components/loader/Loader'
import DowngradeToFreeModal from '@configurator/containers/billing/DowngradeToFreeModalContainer'
import PaymentModal from '@configurator/containers/billing/PaymentContainer'
import { ABO_STATUS, PAYMENT_STEPPER_TYPES, PLAN_TYPE } from 'packages/enum'
import { useMediaBreakpoints } from 'packages/helpers/useMediaQuery'
import { ANALYTICS } from 'packages/mixpanel/Mixpanel'
import CancelledCard from './Cards/CancelledCard'
import FreeCard from './Cards/FreeCard'
import LifetimeCard from './Cards/LifetimeCard'
import PaidCard from './Cards/PaidCard'
import PastDueCard from './Cards/PastDueCard'
import TrialActiveCard from './Cards/TrialActiveCard'
import TrialEndedCard from './Cards/TrialEndedCard'
import UnpaidCard from './Cards/UnpaidCard'

import { Container, Header, Subheading } from './nodes'

const periods = {
  yearly: 'year',
  monthly: 'month',
  daily: 'day',
}

const intervalName = {
  month: 'Monthly',
  year: 'Yearly',
  day: 'Daily',
}

const Subscription = ({
  loading,
  billingCreateCheckoutSession,
  billingCreateCustomerPortal,
  billingTrialStart,
  stripe,
  plans,
  intl: { formatMessage },
}) => {
  const breakpoints = useMediaBreakpoints()

  const {
    aboStatus,
    interval,
    currentPeriodEnd,
    planType,
    cancelAtPeriodEnd,
    trialUsed,
    loading: isLoadingSubscription,
  } = useSubscription()

  const [downgradeModalOpen, setDowngradeModalOpen] = useState(false)

  const [paymentModalOpen, setPaymentModalOpen] = useState(false)

  const isFree = aboStatus === ABO_STATUS.FREE
  const isTrial = aboStatus === ABO_STATUS.TRIALING
  const isPaid = aboStatus === ABO_STATUS.ACTIVE
  const isTrialEnded = aboStatus === ABO_STATUS.TRIAL_ENDED
  const isPastDue = aboStatus === ABO_STATUS.PAST_DUE
  const isCancelled = aboStatus === ABO_STATUS.CANCELLED
  const isUnpaid = aboStatus === ABO_STATUS.UNPAID
  const isLifetime = aboStatus === ABO_STATUS.LIFETIME

  const handleSubscription = useCallback(
    async (selectedPlanId) => {
      ANALYTICS.billingThirdStep()

      const res = await billingCreateCheckoutSession({
        priceId: selectedPlanId,
        clientReferenceId: null,
      })

      const sessionId = get(res, 'data.billingCreateCheckoutSession.data.sessionId')

      sessionId && stripe && stripe.redirectToCheckout({ sessionId })
    },
    [billingCreateCheckoutSession, stripe]
  )

  const onManageSubscription = useCallback(async () => {
    const res = await billingCreateCustomerPortal()

    if (get(res, 'data.billingCreateCustomerPortal.data.sessionId')) {
      window.location.href = get(res, 'data.billingCreateCustomerPortal.data.sessionId')
    }
  }, [billingCreateCustomerPortal])

  useEffect(() => {
    if (plans?.length && !isLoadingSubscription) {
      const urlParams = new URLSearchParams(window.location.search)
      const periodParam = urlParams.get('period')
      const planParam = urlParams.get('plan')

      const period = periods[periodParam]

      if (period && planParam) {
        const selectedPlan = plans.find((plan) => {
          const planName = plan.planType.toLowerCase()

          return plan.interval === period && planName === planParam
        })

        if (selectedPlan) {
          if (isFree || isTrial || isTrialEnded || isCancelled) {
            handleSubscription(selectedPlan.id)
          } else {
            if (!isLifetime) {
              onManageSubscription()
            }
          }
        }
      }
    }
  }, [
    handleSubscription,
    plans,
    isLoadingSubscription,
    isFree,
    isTrial,
    isTrialEnded,
    isCancelled,
    isLifetime,
    onManageSubscription,
  ])

  if (loading || isLoadingSubscription) {
    return <Loader />
  }

  const onDowngradeToFree = () => {
    setDowngradeModalOpen(true)
  }

  const onBillingTrialStart = async () => {
    await billingTrialStart()
  }

  const getPlanName = () => {
    if (isLifetime) {
      return formatMessage({ id: 'accountBilling.billing.plan.lifetimeFree' })
    }

    if (isFree) {
      return formatMessage({ id: 'accountBilling.billing.plan.free' })
    }

    if ((isTrialEnded || isTrial) && planType === PLAN_TYPE.PROMAX) {
      return formatMessage({ id: 'accountBilling.billing.plan.proMax14Days' })
    }

    if (planType === PLAN_TYPE.PROMAX) {
      return formatMessage({ id: 'accountBilling.billing.plan.proMax' })
    }

    if (planType === PLAN_TYPE.PRO) {
      return formatMessage({ id: 'accountBilling.billing.plan.pro' })
    }

    if (planType === PLAN_TYPE.PROLITE) {
      return formatMessage({ id: 'accountBilling.billing.plan.proLite' })
    }

    if (planType === PLAN_TYPE.BASIC) {
      return formatMessage({ id: 'accountBilling.billing.plan.basic' })
    }
  }

  const renderContent = () => {
    if (isFree) {
      return (
        <FreeCard
          onSelectPlan={() => setPaymentModalOpen(true)}
          startTrial={onBillingTrialStart}
          trialUsed={trialUsed}
          planName={getPlanName()}
        />
      )
    } else if (isTrial) {
      return (
        <TrialActiveCard
          onSelectPlan={() => setPaymentModalOpen(true)}
          planName={getPlanName()}
          onDowngradeToFree={onDowngradeToFree}
          renewsOn={currentPeriodEnd}
        />
      )
    } else if (isTrialEnded) {
      return (
        <TrialEndedCard
          onSelectPlan={() => setPaymentModalOpen(true)}
          planName={getPlanName()}
          onDowngradeToFree={onDowngradeToFree}
        />
      )
    } else if (isPaid) {
      return (
        <PaidCard
          cancelAtPeriodEnd={cancelAtPeriodEnd}
          planName={getPlanName()}
          onManageSubscription={onManageSubscription}
          renewsOn={currentPeriodEnd}
          interval={intervalName[interval]}
        />
      )
    } else if (isPastDue) {
      return (
        <PastDueCard
          planName={getPlanName()}
          onManageSubscription={onManageSubscription}
          renewsOn={currentPeriodEnd}
          interval={intervalName[interval]}
        />
      )
    } else if (isUnpaid) {
      return (
        <UnpaidCard
          planName={getPlanName()}
          onManageSubscription={onManageSubscription}
          interval={intervalName[interval]}
        />
      )
    } else if (isCancelled) {
      return (
        <CancelledCard
          onSelectPlan={() => setPaymentModalOpen(true)}
          planName={getPlanName()}
          interval={intervalName[interval]}
          onDowngradeToFree={onDowngradeToFree}
        />
      )
    } else if (isLifetime) {
      return <LifetimeCard planName={getPlanName()} />
    }
  }

  return (
    <Container>
      <Header>
        {formatMessage({ id: 'accountBilling.billing.header' })}

        {breakpoints.sm ? (
          <Subheading>
            {formatMessage({ id: 'accountBilling.billing.subHeadingMobile' })}
          </Subheading>
        ) : (
          <Subheading>{formatMessage({ id: 'accountBilling.billing.subHeading' })}</Subheading>
        )}

        {renderContent()}
      </Header>

      {downgradeModalOpen && (
        <DowngradeToFreeModal open onClose={() => setDowngradeModalOpen(false)} />
      )}

      {paymentModalOpen && (
        <PaymentModal
          open
          onClose={() => setPaymentModalOpen(false)}
          type={PAYMENT_STEPPER_TYPES.BILLING_UPGRADE}
          toType={PLAN_TYPE.PROMAX}
        />
      )}
    </Container>
  )
}

Subscription.propTypes = {
  intl: intlShape.isRequired,
  billingCreateCustomerPortal: PropTypes.func.isRequired,
  billingCreateCheckoutSession: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  plans: PropTypes.array,
}

Subscription.defaultProps = {
  subscription: [],
}

export default injectIntl(injectStripe(Subscription))
