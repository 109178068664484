import { ConnectedRouter } from 'connected-react-router/immutable'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/ja'
import createApolloClient from 'packages/apollo-client'
import { COOKIE } from 'packages/enum'
import { getDefaultLocale, fetchMessages } from 'packages/intl'
import { intlSelector } from 'packages/intl/redux'
import mainTheme from 'packages/styledComponentsThemes/main'
import { apolloState } from 'packages/utils/hydrateInitialState'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'
import { ApolloProvider } from 'react-apollo'
import { Cookies } from 'react-cookie'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import { addLocaleData, IntlProvider } from 'react-intl'
import { updateIntl } from 'react-intl-redux'
import deLocaleData from 'react-intl/locale-data/de'
import enLocaleData from 'react-intl/locale-data/en'
import esLocaleData from 'react-intl/locale-data/es'
import frLocaleData from 'react-intl/locale-data/fr'
import itLocaleData from 'react-intl/locale-data/it'
import ptLocaleData from 'react-intl/locale-data/pt'
import { connect, Provider, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import Layout from './components/Layout'
import { SubscriptionProvider } from './providers/subscription'
import store from './redux/createStore'
import { history } from './redux/initialMiddlewares'

// Set react-intl locales
addLocaleData([
  ...deLocaleData,
  ...enLocaleData,
  ...frLocaleData,
  ...esLocaleData,
  ...itLocaleData,
  ...ptLocaleData,
])

// Create apollo client. Cookies are needed for authentication
export const apollo = createApolloClient({
  cookies: new Cookies(),
  apolloState,
  dispatch: store.dispatch,
})

function useSetLocale() {
  const dispatch = useDispatch()
  useEffect(() => {
    const userLocale = new Cookies().get(COOKIE.locale)
    if ((userLocale && userLocale !== getDefaultLocale()) || userLocale !== 'en') {
      fetchMessages({ locale: userLocale })
        .then((messages) => {
          dispatch(updateIntl(messages))
        })
        /* eslint-disable */
        .catch((err) => console.error(`Невозможно загрузить словари, ${err.message}`))
    }
  }, [dispatch])
}

function isTouchDevice() {
  return (
    typeof window !== 'undefined' &&
    typeof navigator !== 'undefined' &&
    ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
  )
}


const ConnectedLayout = connect((state) => ({ intl: intlSelector(state) }))(({ intl }) => {
  useSetLocale()

  moment.locale(intl.locale)

  console.log('defaultLocale configurator', getDefaultLocale())
  return (
    <IntlProvider defaultLocale={getDefaultLocale()} {...intl}>
      <ConnectedRouter history={history}>
        <SubscriptionProvider>
          <Layout store={store} />
        </SubscriptionProvider>
      </ConnectedRouter>
    </IntlProvider>
  )
})
const App = () => (
  <ApolloProvider client={apollo}>
    <ThemeProvider theme={mainTheme}>
      <Provider store={store}>
        <PostHogProvider
          apiKey={'phc_GTe6SZrEeuDVZuej3poAyI3SjAzlI1Xfc8rk77T7hae'}
          options={{
            session_recording: {
              // WARNING: Only enable this if you understand the security implications
              recordCrossOriginIframes: true,
            },
            api_host: 'https://eu.posthog.com',
          }}
        >
          <DndProvider
            backend={isTouchDevice() ? TouchBackend : HTML5Backend}
            options={{
              enableMouseEvents: true,
              enableHoverOutsideTarget: true,
              ignoreContextMenu: true,
              delayTouchStart: 100,
            }}
          >
            <ConnectedLayout />
          </DndProvider>
        </PostHogProvider>
      </Provider>
    </ThemeProvider>
  </ApolloProvider>
)

export default App
