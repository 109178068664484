const { SERVICE } = require('../enum')

const messagesPaths = {
  common: {
    en: './messages/common/messages.en.js',
    de: './messages/common/messages.de.js',
    fr: './messages/common/messages.fr.js',
    it: './messages/common/messages.it.js',
    es: './messages/common/messages.es.js',
    pt: './messages/common/messages.pt.js',
  },
  [SERVICE.configurator]: {
    en: './messages/configurator/messages.en.js',
    de: './messages/configurator/messages.de.js',
    fr: './messages/configurator/messages.fr.js',
    it: './messages/configurator/messages.it.js',
    es: './messages/configurator/messages.es.js',
    pt: './messages/configurator/messages.pt.js',
  },
  [SERVICE.landing]: {
    en: './messages/landing/messages.en.js',
    de: './messages/landing/messages.de.js',
    fr: './messages/landing/messages.fr.js',
    it: './messages/landing/messages.it.js',
    es: './messages/landing/messages.es.js',
    pt: './messages/landing/messages.pt.js',
  },
}

const locale = navigator?.language || ''
const SUPPORTED_LANGUAGES = {
  en: 'English',
  de: 'German',
  fr: 'French',
  it: 'Italian',
  es: 'Spanish',
  pt: 'Portuguese',
}
// Get language code from navigator.language (taking only the first 2 chars in case of extended codes like 'en-US')
const browserLang = locale.split('-')[0]
// Use browser language if supported, otherwise fallback to defaultLocale
let lang = SUPPORTED_LANGUAGES[browserLang] ? browserLang : 'en'

export const getDefaultLocale = () => {
  return lang
}

export const defaultType = SERVICE.configurator

async function importMessages(path) {
  return new Promise((resolve, reject) => {
    let importDictionary
    if (path === messagesPaths[SERVICE.configurator].en) {
      importDictionary = import('./messages/configurator/messages.en.js')
    }
    if (path === messagesPaths[SERVICE.configurator].de) {
      importDictionary = import('./messages/configurator/messages.de.js')
    }
    if (path === messagesPaths[SERVICE.configurator].fr) {
      importDictionary = import('./messages/configurator/messages.fr.js')
    }
    if (path === messagesPaths[SERVICE.configurator].it) {
      importDictionary = import('./messages/configurator/messages.it.js')
    }
    if (path === messagesPaths[SERVICE.configurator].es) {
      importDictionary = import('./messages/configurator/messages.es.js')
    }
    if (path === messagesPaths[SERVICE.configurator].pt) {
      importDictionary = import('./messages/configurator/messages.pt.js')
    }
    if (path === messagesPaths.common.en) {
      importDictionary = import('./messages/common/messages.en.js')
    }
    if (path === messagesPaths.common.de) {
      importDictionary = import('./messages/common/messages.de.js')
    }
    if (path === messagesPaths.common.fr) {
      importDictionary = import('./messages/common/messages.fr.js')
    }
    if (path === messagesPaths.common.es) {
      importDictionary = import('./messages/common/messages.es.js')
    }
    if (path === messagesPaths.common.it) {
      importDictionary = import('./messages/common/messages.it.js')
    }
    if (path === messagesPaths.common.pt) {
      importDictionary = import('./messages/common/messages.pt.js')
    }

    if (!importDictionary) {
      console.error(`${path} не найден`)
      return reject(`${path} не найден`)
    }

    importDictionary
      .then((module) => {
        resolve(module.default)
      })
      .catch((err) => reject(err))
  })
}

/**
 * Returns translated messages for locale and type
 * @param {string} locale One of 'en', 'de'.
 * @param {string} type One of 'landing', 'configurator'
 * @return {{locale: string, messages: {}}}
 */
export async function fetchMessages({ locale = getDefaultLocale(), type = defaultType }) {
  type = type in messagesPaths ? type : defaultType
  locale = locale in messagesPaths[type] ? locale : getDefaultLocale()

  const messages = await importMessages(messagesPaths[type][locale])
  const messagesCommon = await importMessages(messagesPaths.common[locale])
  return {
    locale,
    type,
    messages: {
      ...messagesCommon,
      ...messages,
    },
  }
}
