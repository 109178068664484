export const planTypeDescription = {
  PROLITE: 'subscription.plan.light.description',
  PRO: 'subscription.plan.pro.description',
  PROMAX: 'subscription.plan.promax.description',
  BASIC: 'subscription.plan.promax.description',
}

export const featuresBox = {
  PROLITE: {
    title: 'features.box.prolite.title',
    lists: [
      'features.box.prolite.list.1',
      'features.box.prolite.list.2',
      'features.box.prolite.list.3',
      'features.box.prolite.list.4',
      'features.box.prolite.list.5',
      'features.box.prolite.list.6',
      'features.box.prolite.list.7',
      'features.box.prolite.list.8',
      'features.box.prolite.list.9',
      'features.box.prolite.list.10',
      'features.box.prolite.list.11',
      'features.box.prolite.list.12',
      'features.box.prolite.list.13',
      'features.box.prolite.list.14',
      'features.box.prolite.list.15',
    ],
  },
  PRO: {
    title: 'features.box.pro.title',
    lists: [
      'features.box.pro.list.1',
      'features.box.pro.list.2',
      'features.box.pro.list.3',
      'features.box.pro.list.4',
      'features.box.pro.list.5',
      'features.box.pro.list.6',
      'features.box.pro.list.7',
      'features.box.pro.list.8',
      'features.box.pro.list.9',
      'features.box.pro.list.10',
      'features.box.pro.list.11',
      'features.box.pro.list.12',
    ],
  },
  PROMAX: {
    title: 'features.box.promax.title',
    lists: [
      'features.box.promax.list.1',
      'features.box.promax.list.2',
      'features.box.promax.list.3',
      'features.box.promax.list.4',
      'features.box.promax.list.5',
      'features.box.promax.list.6',
      'features.box.promax.list.7',
      'features.box.promax.list.8',
      'features.box.promax.list.9',
      'features.box.promax.list.10',
      'features.box.promax.list.11',
      'features.box.promax.list.12',
      'features.box.promax.list.13',
    ],
  },
  BASIC: {
    title: 'features.box.prolite.title',
    lists: [
      'features.box.prolite.list.1',
      'features.box.prolite.list.2',
      'features.box.prolite.list.3',
      'features.box.prolite.list.4',
      'features.box.prolite.list.5',
      'features.box.prolite.list.6',
      'features.box.prolite.list.7',
      'features.box.prolite.list.8',
      'features.box.prolite.list.9',
      'features.box.prolite.list.10',
      'features.box.prolite.list.11',
      'features.box.prolite.list.12',
      'features.box.prolite.list.13',
      'features.box.prolite.list.14',
      'features.box.prolite.list.15',
    ],
  },
}

export const pricesInUSD = {
  monthly: {
    BASIC: 2.0,
    PROLITE: 9.99,
    PRO: 15.99,
    PROMAX: 26.99,
  },
  annually: {
    BASIC: 2.0,
    PROLITE: 7.91,
    PRO: 13.16,
    PROMAX: 21.99,
  },
}
